import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link, withRouter} from 'react-router-dom'
import {getFlag} from 'store/flag/selectors'
import {getSector} from 'store/user/selectors'

import {faStoreAlt, faSearch, faDoorOpen, faBoxOpen} from '@fortawesome/free-solid-svg-icons'

const MenuItem = withRouter(({pathname, title, label, icon}) => {
  const isActive = location.pathname === pathname
  return (
    <Link to={{pathname}} title={title} className={`no-underline`}>
      <div
        className={`flex py-10 items-center justify-between border-l-4 hover:bg-grey-lightest ${
          isActive && 'border-palette-red'
        }`}
      >
        <div className="flex-shrink ml-20 mr-5 w-px-30">
          <FontAwesomeIcon className="text-20 text-grey" icon={icon} />
        </div>
        <div className="flex-1 text-left">{label}</div>
      </div>
    </Link>
  )
})

MenuItem.propTypes = {
  location: PropTypes.object,
  pathname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
}

const MenuItems = (props) => (
  <div className="flex flex-col justify-between mt-30 text-14 text-left uppercase font-bold">
    <MenuItem pathname={`/sector/${props.sector}/huts`} title="Hut Search" label="Hut Search" icon={faSearch} />
    <MenuItem
      pathname={`/sector/${props.sector}/huts/dashboard`}
      title="Hut Availability"
      label="Hut Availability"
      icon={faStoreAlt}
    />
    {props.enableOrderManagement && (
      <MenuItem
        pathname={`/sector/${props.sector}/orders/91cdc83b-371c-4b7b-ae28-97532f5139be`}
        title="Orders"
        label="Orders"
        icon={faBoxOpen}
      />
    )}
    <MenuItem pathname="/logout" title="Logout" label="Logout" icon={faDoorOpen} />
  </div>
)

MenuItems.propTypes = {
  enableOrderManagement: PropTypes.bool,
  sector: PropTypes.string,
}

export default connect((state) => ({
  enableOrderManagement: getFlag(state, 'enableOrderManagement'),
  sector: getSector(state),
}))(MenuItems)
