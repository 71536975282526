import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import debounce from 'lodash/debounce'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import SearchResultsList from 'common/SearchResultsList'
import {queryHutsMeta} from '../modules/hut-dashboard/store/huts-meta/selectors'
import {getSector} from '../store/user/selectors'

export const propTypes = {
  queryStores: PropTypes.any,
  sector: PropTypes.string,
}

class Search extends React.Component {
  state = {
    query: '',
    results: [],
    focussed: false,
    minQueryLength: 2,
  }

  reset() {
    this.setState({query: ''})
    this.setState({results: []})
  }

  setFocussed() {
    this.setState({focussed: true})
  }

  unsetFocussed() {
    this.setState({focussed: false})
  }

  shouldShowResults() {
    return this.state.focussed && this.state.results.length > 0
  }

  fetchResults = debounce((query) => {
    if (`${query}`.length < this.state.minQueryLength) {
      return
    }
    const {queryStores} = this.props
    const results = queryStores(query)
    this.setState({results})
  }, 200)

  handleInputChange = () => {
    const query = this.queryInput.value
    this.setState({query})
    this.fetchResults(query)
  }

  onItemSelected(/* item */) {
    this.unsetFocussed()
    this.reset()
  }

  render() {
    const {sector} = this.props
    return (
      <div className="w-full md:w-1/3 md:mr-4 text-palette-grey" onFocus={() => this.setFocussed()}>
        <div className="flex items-center px-20 md:px-10 py-10 md:py-2 pr-8 md:rounded border border-palette-grey-lighter md:border-palette-grey">
          <FontAwesomeIcon className="text-18 text-grey" icon="search" />
          <input
            className="flex-1 appearance-none outline-none ml-5 p-5 text-14 font-bold text-grey"
            ref={(el) => {
              this.queryInput = el
            }}
            type="text"
            value={this.state.query}
            onChange={this.handleInputChange}
            placeholder="Search for Huts..."
          />
        </div>
        {this.shouldShowResults() && (
          <SearchResultsList
            className="mt-80"
            items={this.state.results}
            onItemSelected={(item) => this.onItemSelected(item)}
            onClosed={() => this.reset()}
            baseItemPath={`/sector/${sector}/huts`}
          />
        )}
      </div>
    )
  }
}

Search.propTypes = propTypes

export const mapStateToProps = (state) => {
  return {
    queryStores: (query) => queryHutsMeta(state, query),
    sector: getSector(state),
  }
}

export default connect(mapStateToProps)(Search)
