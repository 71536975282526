import React, {Component} from 'react'
import {connect} from 'react-redux'
import {fetchOrder} from '../store/actions'
import Payment from '../components/Payment'
import Customer from '../components/Customer'
import OrderSummary from '../components/OrderSummary'
import PosResponseSummary from '../components/PosResponseSummary'
import HutSummary from '../components/HutSummary'
import EventHistory from '../components/EventHistory'

export class Order extends Component {
  componentWillMount() {
    const {fetchOrder} = this.props
    if (!this.props.order && !this.props.isFetching) {
      fetchOrder(this.props.transactionId)
    }
  }

  render() {
    if (this.props.error) {
      return <span>{this.props.error.message}</span>
    }

    if (this.props.isFetching || !this.props.order) {
      return <span>Loading...</span>
    }

    const orderPayload = this.props.order

    return (
      <main className="content md:flex flex-col">
        <OrderSummary orderPayload={orderPayload} />

        <EventHistory stateTransitions={orderPayload.stateTransitions} />

        <PosResponseSummary posResponse={orderPayload.posResponse} hut={orderPayload.order.hut} />

        <HutSummary hut={orderPayload.order.hut} />

        <Payment payment={orderPayload.order.payment} />

        <Customer customer={orderPayload.order.customer} />
        {/* loyalty info */}
      </main>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const {
    match: {params},
  } = props
  return {
    transactionId: params.transactionId,
    order: state.order.order,
    status: state.order.status,
    stateTransitions: state.order.stateTransitions,
    isFetching: state.order.isFetching,
    error: state.order.error,
  }
}

export const mapDispatchToProps = (dispatch) => ({
  fetchOrder: (transactionId) => {
    dispatch(fetchOrder(transactionId))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Order)
