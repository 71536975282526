import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {permittedSectors} from 'store/auth/selectors'
import {setSector} from 'store/user/actions'
import {Formik, Field} from 'formik'
import {getAvailableSectors} from 'utils/sectors'
import {getSector} from 'store/user/selectors'
import {fetchAllHutsMetadata} from '../modules/hut-dashboard/store/huts-meta/actions'

export const propTypes = {
  sector: PropTypes.string,
  setSector: PropTypes.func.isRequired,
  permittedSectors: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
}

class SectorSelect extends React.Component {
  switchSector = (event) => {
    const {setSector, history, fetchAllHutsMetadata} = this.props

    const sector = event.currentTarget.value
    setSector(sector)
    fetchAllHutsMetadata()
    history.push(`/sector/${sector}/huts`)
  }

  render() {
    const {permittedSectors, sector} = this.props
    if (!sector) {
      return null
    }

    return (
      <div className="flex items-center justify-between mb-0 ml-auto p-10 md:pd-0 text-grey">
        <Formik
          initialValues={{sector}}
          render={() => (
            <div className="block w-auto pr-10">
              <Field component="select" name="sector" className="m-0" value={sector} onChange={this.switchSector}>
                {getAvailableSectors(permittedSectors).map((s, index) => {
                  return (
                    <option key={index} value={s.sector}>
                      {s.label}
                    </option>
                  )
                })}
              </Field>
            </div>
          )}
        />
      </div>
    )
  }
}

SectorSelect.propTypes = propTypes

export const mapStateToProps = (state) => {
  return {
    sector: getSector(state),
    permittedSectors: permittedSectors(state),
  }
}

export const mapDispatchToProps = (dispatch) => ({
  setSector: (sectorChoice) => dispatch(setSector(sectorChoice)),
  fetchAllHutsMetadata: () => dispatch(fetchAllHutsMetadata()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectorSelect))
