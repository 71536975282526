import logger from 'utils/logger'

let testResult

export default function supportsLocalStorage() {
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'test' && testResult !== undefined) {
    return testResult
  }

  // Safari in private mode will throw an error here
  try {
    if (typeof localStorage !== 'object' || !localStorage) return (testResult = false)
    localStorage.setItem('testItem', 1)
    localStorage.removeItem('testItem')
  } catch (err) {
    logger &&
      logger.info &&
      logger.info({
        msg: 'No local storage',
        url: window.location.href,
        error: err.toString(),
      })

    return (testResult = false)
  }

  return (testResult = true)
}

export function checkLocalStorage(hut, flagLevel) {
  if (!supportsLocalStorage()) {
    logger &&
      logger.info &&
      logger.info({
        msg: 'No local storage',
        url: window.location.href,
        hut,
        featureRole: flagLevel,
        ua: navigator && navigator.userAgent,
      })
  }
}
