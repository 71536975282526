import {getSector} from 'store/user/selectors'
import {hutsMetaList} from '../huts-meta/selectors'
import {hutsStatusList, closedDueToConnectivity} from '../huts-status/selectors'
import keyBy from 'lodash/keyBy'
import {compareHutsByClosedAndDowntime} from '../huts-meta/helpers'


export const huts = state => state.hutsPosDowntime

export const hutsPosDowntime = (state) => {
  const sector = getSector(state)
  return huts(state) && huts(state)[sector]
}

export const isFetchingHutsPosDowntime = (state) => {
  const hs = hutsPosDowntime(state)
  return hs && hs.isFetching ? hs.isFetching : false
}

export const hutsPosDowntimeList = (state) => {
  const hs = hutsPosDowntime(state)
  return hs && hs.list ? hs.list : {}
}

export const hutsPosDowntimeReceivedAt = (state) => {
  const hs = hutsPosDowntime(state)
  return hs && hs.receivedAt ? hs.receivedAt : 0
}

export const hutsPosDowntimeByHutId = (state, hutId) => {
  return hutsPosDowntimeList(state)[hutId]
}

export const joinMetaPosDowntimeHuts = (state, timePeriodFilter, sortOrder) => {
  const hm = hutsMetaList(state)
  const hpd = hutsPosDowntimeList(state)
  const hsl = keyBy(hutsStatusList(state), 'hutId')

  if (!hm || !hpd) return []

  const list = Object.keys(hpd)
  .filter(hutId => {
    return hpd[hutId].durationTotal !== 'P0D'
  })
  .map(hutId => {
    const hutMeta = hm[hutId]
    const hutPosDowntime = hpd[hutId]
    const hutStatus = hsl[hutId]
    return {
      ...hutMeta,
      ...hutStatus,
      downtime: hutPosDowntime.durationTotal,
      isClosed: closedDueToConnectivity(hutStatus),
    }
  }).filter(hut => {
    if (timePeriodFilter === 'currently') {
      return closedDueToConnectivity(hut)
    }
    return true
  })

  list.sort(compareHutsByClosedAndDowntime)

  if(sortOrder === 'longestToShortest') {
      list.reverse()
  }

  return list
}