import {getSector} from 'store/user/selectors'

export const huts = (state) => state.hutsStatusCollectionClosures

export const hutsStatusCollectionClosures = (state) => {
  const sector = getSector(state)
  return huts(state)[sector]
}

export const isFetchingHutsCollectionClosures = (state) => {
  const hs = hutsStatusCollectionClosures(state)
  return hs && hs.isFetching ? hs.isFetching : false
}

export const hutsStatusCollectionClosuresList = (state) => {
  const hs = hutsStatusCollectionClosures(state)
  return hs && hs.list ? hs.list : {}
}

export const hutsStatusCollectionClosuresReceivedAt = (state) => {
  const hs = hutsStatusCollectionClosures(state)
  return hs && hs.receivedAt ? hs.receivedAt : 0
}

export const hutsStatusCollectionClosuresByHutId = (state, hutId) => {
  return hutsStatusCollectionClosuresList(state)[hutId]
}
