import React from 'react'

export default (props) => (<div className="bg-white rounded-6 shadow-medium border-palette-grey-border flex items-center mx-20 my-5 border md:mx-0 p-10 md:py-20">
  <div className="flex flex-row items-start">
    <div className="flex p-10 md:py-20">
      <div className={`square-40x40 flex justify-center items-center rounded-full bg-palette-amber-lightest mr-20`}>
        {props.icon}
      </div>
      <div className="flex-1 flex flex-col text-left">
        <div className="flex-1 flex-col text-left font-bold justify-start items-center">
          <div className="flex-1 mr-5 mb-5 text-18 font-bold">
            {props.title}
          </div>
        </div>
        <div className="flex-1 text-11 mt-5 items-align text-palette-grey-dark capitalize">
          {props.children}
        </div>
      </div>
    </div>
  </div>
</div>
)