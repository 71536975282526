import {postHourUpdates} from '../hours/actions'
import _ from 'lodash'

export const FETCH_REGULAR_HOURS_FOR_HUT_IN_PROGRESS = 'FETCH_REGULAR_HOURS_FOR_HUT_IN_PROGRESS'
export const FETCH_REGULAR_HOURS_FOR_HUT_SUCCESS = 'FETCH_REGULAR_HOURS_FOR_HUT_SUCCESS'
export const FETCH_REGULAR_HOURS_FOR_HUT_FAILURE = 'FETCH_REGULAR_HOURS_FOR_HUT_FAILURE'


export const fetchRegularHoursForHutInProgress = (sector, hutId) => {
  return {
    type: FETCH_REGULAR_HOURS_FOR_HUT_IN_PROGRESS,
    sector,
    hutId,
  }
}

export const regularHoursForHutSuccess = (sector, hutId, regularHoursByDay) => {
  return {
    type: FETCH_REGULAR_HOURS_FOR_HUT_SUCCESS,
    sector,
    hutId,
    regularHoursByDay,
  }
}

export const fetchRegularHoursForHutFailure = (sector, hutId, error) => {
  return {
    type: FETCH_REGULAR_HOURS_FOR_HUT_FAILURE,
    sector,
    hutId,
    error,
  }
}

export const fetchRegularHoursForHut = (sector, hutId, date) => {
  return async (dispatch) => {
    try {
      dispatch(fetchRegularHoursForHutInProgress(sector, hutId))

      const url = `https://0136icutm4.execute-api.eu-west-1.amazonaws.com/dev/regular-opening-hours?date=${date.format('YYYY-MM-DD')}&sector=${sector}&hutId=${hutId}`
      const result = await fetch(url)
      const response = await result.json()
            
      return dispatch(regularHoursForHutSuccess(sector, hutId, response.data))
    } catch (error) {
      console.error(error)
      return dispatch(fetchRegularHoursForHutFailure(sector, hutId, error))
    }
  }
}


const adjustForClosing = (day, closingTime) => {
  const weekDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN', 'MON'] // ew, find a nicer way
  const comparableClosingTime = Number(closingTime.replace(':', ''))
  
  if (comparableClosingTime <= 600) {
    const nextDay = weekDays.indexOf(day) + 1
    return weekDays[nextDay]
  }
  return day
}

const adjustSlot = (day, slot) => {
  return {
    disposition: slot.disposition,
    openDay: day,
    openTime: slot.open,
    closeTime: slot.close,
    closeDay: adjustForClosing(day, slot.close),
  }
}

export const postRegularHoursForHut = (date, regularHoursForHut) => {
  const hours = _.flatMap(regularHoursForHut.regularHoursByDay, regularHoursForDay => {
    const mergedSlots = regularHoursForDay.hours.DELIVERY.concat(regularHoursForDay.hours.COLLECTION)
    return mergedSlots.map(slot => adjustSlot(regularHoursForDay.day, slot))
  })
  
  return postHourUpdates({
    date,
    hutId: regularHoursForHut.hutId,
    sector: regularHoursForHut.sector,
    type: 'REGULAR',
    hours,
  })
}