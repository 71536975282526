import {getSector, userPreferences} from 'store/user/selectors'
import {from, to} from 'utils/time'

export const FETCH_HUTS_COL_CLOSURES_REQUEST = 'FETCH_HUTS_COL_CLOSURES_REQUEST'
export const FETCH_HUTS_COL_CLOSURES_SUCCESS = 'FETCH_HUTS_COL_CLOSURES_SUCCESS'
export const FETCH_HUTS_COL_CLOSURES_FAILURE = 'FETCH_HUTS_COL_CLOSURES_FAILURE'

export const fetchAllHutsStatusCollectionRequest = (sector) => {
  return {
    type: FETCH_HUTS_COL_CLOSURES_REQUEST,
    sector,
  }
}

export const fetchAllHutsStatusCollectionSuccess = (sector, json) => {
  return {
    type: FETCH_HUTS_COL_CLOSURES_SUCCESS,
    sector,
    list: json.data,
    receivedAt: Date.now(),
  }
}

export const fetchAllHutsStatusCollectionFailure = (sector, error) => {
  return {
    type: FETCH_HUTS_COL_CLOSURES_FAILURE,
    sector,
    error,
  }
}

export const fetchAllHutsStatusCollectionClosuresBySector = (sector, from, to) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAllHutsStatusCollectionRequest(sector))
      const url = `https://api.pizzahut.io/v2/huts/status/${sector}/reports/closures/collection?from=${from}&to=${to}`
      const result = await fetch(url)
      const json = await result.json()
      return dispatch(fetchAllHutsStatusCollectionSuccess(sector, json))
    } catch (error) {
      return dispatch(fetchAllHutsStatusCollectionFailure(sector, error))
    }
  }
}

export const fetchAllHutsStatusCollectionClosures = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const sector = getSector(state)
    const preferences = userPreferences(state)

    return dispatch(fetchAllHutsStatusCollectionClosuresBySector(sector, from(preferences.filter.timePeriod), to()))
  }
}
