export const supportedSectors = [
  // TODO get this from some kind of sector API?
  {label: 'UK', sector: 'uk-1'},
  {label: 'UK Dine In', sector: 'uk-2'},
  {label: 'France', sector: 'fr-1'},
  {label: 'India', sector: 'in-1'},
  {label: 'Canada (Not Authoritative)', sector: 'ca-1'},
]

export function isPermittedSector(permitted, sectorId) {
  return permitted.includes('*') || permitted.includes(sectorId)
}

export function getAvailableSectors(permittedSectors) {
  return supportedSectors.filter(({sector}) => isPermittedSector(permittedSectors, sector))
}
