import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

export const propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    sector: PropTypes.string,
  }),
  isFocussed: PropTypes.bool,
  onSelected: PropTypes.func,
  baseItemPath: PropTypes.string,
}

class SearchResult extends React.Component {
  focusOnMount(node) {
    if (node && node.focus && this.props.isFocussed) {
      node.focus()
    }
  }

  render() {
    const {item, onSelected, baseItemPath} = this.props
    const {id, name} = item
    return (
      <Link
        ref={el => {
          this.element = el
        }}
        className="w-full block inset-0 p-10 outline-none hover:bg-grey-lightest focus:bg-grey-lightest text-left z-100 text-14"
        title={`Hut ${name} page`}
        to={{pathname: `${baseItemPath}/${id}`}}
        innerRef={node => this.focusOnMount(node)}
        onClick={() => onSelected(item)}
      >
        {id} - {name}
      </Link>
    )
  }
}

SearchResult.propTypes = propTypes

export default SearchResult
