// TODO: Lang labels
// Status routes are physical UI views as well as filters on the data
export const statusRoutes = [
  {
    route: 'normal',
    redirectTo: 'normal/open',
    label: 'Operating Normally',
    colour: 'green',
    showFilterForm: false,
  },
  {
    route: 'normal/open',
    label: 'Open',
    colour: 'green',
    showFilterForm: false,
  },
  {
    route: 'normal/out-of-hours',
    label: 'Out of Hours',
    colour: 'green',
    showFilterForm: false,
  },
  {
    route: 'partially-closed',
    redirectTo: 'partially-closed/delivery',
    label: 'Partially Closed',
    colour: 'amber',
    showFilterForm: true,
  },
  {
    route: 'partially-closed/delivery',
    label: 'To Delivery',
    colour: 'amber',
    showFilterForm: true,
  },
  {
    route: 'partially-closed/collection',
    label: 'To Collection',
    colour: 'amber',
    showFilterForm: true,
  },
  {
    route: 'closed',
    redirectTo: 'closed/manually',
    label: 'Closed',
    colour: 'red',
    showFilterForm: true,
  },
  {
    route: 'closed/manually',
    label: 'Manually',
    colour: 'red',
    showFilterForm: true,
  },
  {
    route: 'closed/connectivity',
    label: 'Connection',
    colour: 'red',
    showFilterForm: true,
  },
]
