import {
  SET_USER_PREFERENCES,
  SET_SECTOR,
  APPLY_FILTER_REQUEST,
  APPLY_FILTER_FINISHED,
} from './actions'

const defaultState = {
  sector: null,
  preferences: {
    filter: {
      timePeriod: 'currently',
    },
    sort: {
      downtime: 'longestToShortest',
    },
  },
  applyingFilter: false,
  openingHourChanges: null,
}

export const user = (state = {}, action) => {
  switch (action.type) {
    case SET_USER_PREFERENCES:
      return {
        ...state,
        preferences: action.preferences,
      }
    case SET_SECTOR:
      return {
        ...state,
        sector: action.sector,
      }
    case APPLY_FILTER_REQUEST:
      return {
        ...state,
        applyingFilter: true,
      }
    case APPLY_FILTER_FINISHED:
      return {
        ...state,
        applyingFilter: false,
      }
    default:
      return {
        ...defaultState,
        ...state,
      }
  }
}
