import keyBy from 'lodash/keyBy'
import {
  FETCH_HUTS_TOTAL_CLOSURES_REQUEST,
  FETCH_HUTS_TOTAL_CLOSURES_SUCCESS,
  FETCH_HUTS_TOTAL_CLOSURES_FAILURE,
} from './actions'

export const hutList = (
  state = {
    isFetching: false,
    receivedAt: null,
    list: [],
  },
  action
) => {
  switch (action.type) {
    case FETCH_HUTS_TOTAL_CLOSURES_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_HUTS_TOTAL_CLOSURES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: keyBy(
          action.list.filter((hut) => hut.durationTotal !== 'P0D'),
          'hutId'
        ),
        receivedAt: action.receivedAt,
      }
    case FETCH_HUTS_TOTAL_CLOSURES_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}

export const hutsStatusTotalClosures = (state = {}, action) => {
  switch (action.type) {
    case FETCH_HUTS_TOTAL_CLOSURES_REQUEST:
    case FETCH_HUTS_TOTAL_CLOSURES_SUCCESS:
    case FETCH_HUTS_TOTAL_CLOSURES_FAILURE:
      return {
        ...state,
        [action.sector]: hutList(state[action.sector], action),
      }
    default:
      return state
  }
}
