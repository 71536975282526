import React from 'react'
import {Route, Switch} from 'react-router-dom'
import PropTypes from 'prop-types'

import {order} from './store/reducers'

import Order from './routes/Order'

export const OrdersRoutes = ({match}) => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${match.path}/:transactionId`} component={Order} />
      </Switch>
    </React.Fragment>
  )
}

OrdersRoutes.propTypes = {
  match: PropTypes.object,
}

export const reducers = {
  order,
}