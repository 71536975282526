import React from 'react'

export default (props) => {
  if (props.error) {
    // When the loader has errored
    console.error('here', props.error)
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    )
  } else if (props.timedOut) {
    // When the loader has taken longer than the timeout
    return (
      <div>
        Taking a long time... <button onClick={props.retry}>Retry</button>
      </div>
    )
  } else if (props.pastDelay) {
    // When the loader has taken longer than the delay
    return <div>Loading...</div>
  } else {
    // When the loader has just started
    return null
  }
}
