import React, {Fragment} from 'react'
import {render} from 'react-dom'
import configureStore from 'store/create-store'
import {Provider} from 'react-redux'
import createHistory from 'history/createBrowserHistory'
import {Router} from 'react-router'
import Routes from 'routes'
import './css/app.css'
import 'font-icons'
import {exposeFlagUtils} from 'store/flag/util'
import {loadState, saveState} from './utils/reduxStatePersistence'
import Helmet from 'react-helmet'
import favicon from '../favicon.png'

export const getAppContent = (history, store) => {
  return (
    <Fragment>
      <Helmet >
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>

      <Provider store={store}>
        <Router history={history}>
          <Routes />
        </Router>
      </Provider>
    </Fragment>
  )
}

export const appRender = () => {
  const initialState = loadState()
  const history = createHistory()
  const store = configureStore(initialState, history)

  // persist state to local storage for faster reloading
  store.subscribe(() => {
    const {user, auth, flags} = store.getState()
    saveState({user, auth, flags})
  })

  exposeFlagUtils(store)
  
  const appElement = document.getElementById('app')
  render(getAppContent(history, store), appElement)
}

export default appRender()
