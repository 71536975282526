import {setSector} from 'store/user/actions'
import {login, currentUserInfo} from 'utils/aws-cognito'
import {getDefaultSector} from 'store/auth/selectors'
import {removeCredentials, retrieveCredentials, storeCredentials} from 'store/auth/storage'

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE'
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const AUTH_LOGOUT_FAILURE = 'AUTH_LOGOUT_FAILURE'
export const AUTH_REHYDRATE = 'AUTH_REHYDRATE'

export const authLoginRequest = (payload) => {
  return {
    type: AUTH_LOGIN_REQUEST,
    email: payload.email,
  }
}

export const authLoginSuccess = (payload) => {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload,
  }
}

export const authLoginFailure = (error) => {
  return {
    type: AUTH_LOGIN_FAILURE,
    error,
  }
}

export const authLogoutRequest = () => {
  return {
    type: AUTH_LOGOUT_REQUEST,
  }
}

export const authLogoutSuccess = () => {
  return {
    type: AUTH_LOGOUT_SUCCESS,
  }
}

export const authLogoutFailure = (error) => {
  return {
    type: AUTH_LOGOUT_FAILURE,
    error,
  }
}

export const authRehydrate = (desiredSector) => {
  return async (dispatch) => {
    const payload = retrieveCredentials()
    await dispatch({type: AUTH_REHYDRATE, payload})
    return dispatch(authSetDefaultSector(desiredSector))
  }
}

export const authLogin = (loginPayload) => {
  // Note: it's important that this action returns a promise
  // so that we can handle contextual errors without needing
  // to manage them in the redux store
  return async (dispatch) => {
    try {
      dispatch(authLoginRequest(loginPayload))
      const {authentication} = await login({loginPayload})
      const {attributes} = await currentUserInfo()
      const authPayload = {authentication, attributes}
      storeCredentials(authPayload)
      await dispatch(authLoginSuccess(authPayload))
      return dispatch(authSetDefaultSector())
    } catch (err) {
      dispatch(authLoginFailure(err))
      throw err
    }
  }
}

export const authLogout = () => {
  return (dispatch) => {
    try {
      dispatch(authLogoutRequest())
      removeCredentials()
      dispatch(authLogoutSuccess())
    } catch (e) {
      dispatch(authLogoutFailure(e))
    }
  }
}

export const authSetDefaultSector = (desiredSector) => {
  return (dispatch, getState) => {
    const state = getState()
    const defaultSector = getDefaultSector(state)(desiredSector)
    if (defaultSector) {
      dispatch(setSector(defaultSector.sector))
    }
  }
}
