import React from 'react'
import Loadable from 'react-loadable'
import Loading from 'common/Loading'
import {Route, Switch} from 'react-router-dom'
import PropTypes from 'prop-types'

import {hutsStatus} from './store/huts-status/reducers'
import {hutsMeta} from './store/huts-meta/reducers'
import {hutMeta} from './store/hut/reducers'
import {hutsPosDowntime} from './store/huts-pos-downtime/reducers'
import {hutsStatusDeliveryClosures} from './store/huts-delivery-closures/reducers'
import {hutsStatusCollectionClosures} from './store/huts-collection-closures/reducers'
import {hutsStatusTotalClosures} from './store/huts-total-closures/reducers'
import {hours} from './store/hours/reducers'
import {regularHours} from './store/regular-hours/reducers'
import {hutCredentials} from './store/hut-credentials/reducers'

const DashboardLoadable = Loadable({
  loader: () => import('./routes/Dashboard'),
  loading: Loading,
})

const dashboardByStatusLoadable = Loadable({
  loader: () => import('./routes/DashboardByStatus'),
  loading: Loading,
})

const hutLoadable = Loadable({
  loader: () => import('./routes/Hut'),
  loading: Loading,
})

const hutOneOffHoursChangeLoadable = Loadable({
  loader: () => import('./routes/HutOneOffHoursChange'),
  loading: Loading,
})

const hutRegularHoursChangeLoadable = Loadable({
  loader: () => import('./routes/HutRegularHoursChange'),
  loading: Loading,
})

const hutPosDowntimeLoadable = Loadable({
  loader: () => import('./routes/POSDowntime'),
  loading: Loading,
})

const hutsLoadable = Loadable({
  loader: () => import('./routes/Huts'),
  loading: Loading,
})

const credentialsChangeLoadable = Loadable({
  loader: () => import('./routes/CredentialsChange'),
  loading: Loading,
})

export const HutsRoutes = ({match}) => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${match.path}/`} component={hutsLoadable} />
        <Route exact path={`${match.path}/dashboard`} component={DashboardLoadable} />
        <Route exact path={`${match.path}/dashboard/:status`} component={dashboardByStatusLoadable} />
        <Route exact path={`${match.path}/dashboard/:status/:subStatus`} component={dashboardByStatusLoadable} />
        <Route exact path={`${match.path}/:hutId`} component={hutLoadable} />
        <Route exact path={`${match.path}/:hutId/hours/oneoff`} component={hutOneOffHoursChangeLoadable} />
        <Route
          exact
          path={`${match.path}/:hutId/hours/oneoff/:year/:week/:day`}
          component={hutOneOffHoursChangeLoadable}
        />
        <Route exact path={`${match.path}/:hutId/hours/regular`} component={hutRegularHoursChangeLoadable} />
        <Route
          exact
          path={`${match.path}/:hutId/hours/regular/:year/:week/:day`}
          component={hutRegularHoursChangeLoadable}
        />
        <Route exact path={`${match.path}/:hutId/pos-downtime`} component={hutPosDowntimeLoadable} />
        <Route exact path={`${match.path}/:hutId/credentials-change`} component={credentialsChangeLoadable} />
      </Switch>
    </React.Fragment>
  )
}

HutsRoutes.propTypes = {
  match: PropTypes.object,
}

export const reducers = {
  hutsStatus,
  hutsMeta,
  hutMeta,
  hutsPosDowntime,
  hutsStatusDeliveryClosures,
  hutsStatusCollectionClosures,
  hutsStatusTotalClosures,
  hours,
  regularHours,
  hutCredentials,
}
