import {
  FETCH_ALL_HUTS_STATUS_REQUEST,
  FETCH_ALL_HUTS_STATUS_SUCCESS,
  FETCH_ALL_HUTS_STATUS_FAILURE,
  FETCH_HUT_STATUS_REQUEST,
  FETCH_HUT_STATUS_SUCCESS,
  FETCH_HUT_STATUS_FAILURE,
} from './actions'

export const hutList = (
  state = {
    isFetching: false,
    receivedAt: null,
    list: [],
  },
  action
) => {
  switch (action.type) {
    case FETCH_ALL_HUTS_STATUS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_ALL_HUTS_STATUS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.list,
        receivedAt: action.receivedAt,
      }
    case FETCH_ALL_HUTS_STATUS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case FETCH_HUT_STATUS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_HUT_STATUS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: [...state.list, action.status],
        receivedAt: action.receivedAt,
      }
    case FETCH_HUT_STATUS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    default:
      return state
  }
}

export const hutsStatus = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ALL_HUTS_STATUS_REQUEST:
    case FETCH_ALL_HUTS_STATUS_SUCCESS:
    case FETCH_ALL_HUTS_STATUS_FAILURE:
    case FETCH_HUT_STATUS_REQUEST:
    case FETCH_HUT_STATUS_SUCCESS:
    case FETCH_HUT_STATUS_FAILURE:
      return {
        ...state,
        [action.sector]: hutList(state[action.sector], action),
      }
    default:
      return state
  }
}
