import {idToken, attributes} from 'store/auth/selectors'
import logger from 'utils/logger'
import _ from 'lodash'
import {getFlag} from 'store/flag/selectors'

export const RESET_CHANGES = 'RESET_CHANGES'
export const ADD_TIME_CHANGE = 'ADD_TIME_CHANGE'
export const EDIT_TIME_SLOT = 'EDIT_TIME_SLOT'
export const REMOVE_TIME_CHANGE = 'REMOVE_TIME_CHANGE'
export const SAVE_TIME_CHANGES = 'SAVE_TIME_CHANGES'
export const CLEAR_TIME_CHAGES = 'CLEAR_TIME_CHANGES'
export const FETCH_HOURS_FOR_WEEK_REQUEST = 'FETCH_HOURS_FOR_WEEK_REQUEST'
export const FETCH_HOURS_FOR_WEEK_SUCCESS = 'FETCH_HOURS_FOR_WEEK_SUCCESS'
export const FETCH_HOURS_FOR_WEEK_FAILURE = 'FETCH_HOURS_FOR_WEEK_FAILURE'
export const POST_HOURS_UPDATES_REQUEST = 'POST_HOURS_UPDATES_REQUEST'
export const POST_HOURS_UPDATES_SUCCESS = 'POST_HOURS_UPDATES_SUCCESS'
export const POST_HOURS_UPDATES_FAILURE = 'POST_HOURS_UPDATES_FAILURE'

export const resetChanges = (sector, hutId) => {
  return {
    type: RESET_CHANGES,
    sector,
    hutId,
  }
}

export const addTimeChange = (sector, hutId, date, disposition, open, close, id) => {
  return {
    type: ADD_TIME_CHANGE,
    sector,
    hutId,
    date,
    disposition,
    open,
    close,
    id,
  }
}

export const editTimeSlot = (sector, hutId, date, disposition, openOrClose, time, id) => {
  return {
    type: EDIT_TIME_SLOT,
    sector,
    hutId,
    date,
    disposition,
    openOrClose,
    time,
    id,
  }
}

export const removeTimeChange = (sector, hutId, date, disposition, id) => {
  return {
    type: REMOVE_TIME_CHANGE,
    sector,
    hutId,
    date,
    disposition,
    id,
  }
}

export const fetchHoursForWeekRequest = (sector, hutId) => {
  return {
    type: FETCH_HOURS_FOR_WEEK_REQUEST,
    sector,
    hutId,
  }
}

export const fetchHoursForWeekSuccess = (sector, hutId, json) => {
  return {
    type: FETCH_HOURS_FOR_WEEK_SUCCESS,
    sector,
    hut: json,
    hutId,
  }
}

export const fetchHoursForWeekFailure = (sector, hutId, error) => {
  return {
    type: FETCH_HOURS_FOR_WEEK_FAILURE,
    sector,
    error,
    hutId,
  }
}

export const postHoursUpdatesRequest = (sector, hutId) => {
  return {
    type: POST_HOURS_UPDATES_REQUEST,
    sector,
    hutId,
  }
}

export const postHoursUpdatesSuccess = (sector, hutId, json) => {
  return {
    type: POST_HOURS_UPDATES_SUCCESS,
    sector,
    hut: json,
    hutId,
  }
}

export const postHoursUpdatesFailure = (sector, hutId, error) => {
  return {
    type: POST_HOURS_UPDATES_FAILURE,
    sector,
    error,
    hutId,
  }
}

export const fetchHoursForWeekWithHoursApi = (sector, hutId, from, to) => {
  return async (dispatch) => {
    const dateFormat = 'YYYY-MM-DD'
    const fromDate = from.format(dateFormat)
    const toDate = to.format(dateFormat)

    const baseUri = 'https://0136icutm4.execute-api.eu-west-1.amazonaws.com'
    const url = `${baseUri}/dev/opening-hours?from=${fromDate}&to=${toDate}&sector=${sector}&hutId=${hutId}`

    const result = await fetch(url)
    const json = await result.json()
    const shimmedJson = {
      hours: {
        physical: _.flatMap(
          json.data.filter((d) => d.hutId === hutId),
          (d) => d.hours
        ).map((h) => ({...h, disposition: h.disposition.toLowerCase()})),
      },
    }

    return dispatch(fetchHoursForWeekSuccess(sector, hutId, shimmedJson))
  }
}

export const fetchHoursForWeek = (sector, hutId, from, to) => {
  return async (dispatch, getState) => {
    try {
      const state = getState()
      const useHoursApi = getFlag(state, 'useHoursAPI')

      dispatch(fetchHoursForWeekRequest(sector, hutId))

      if (useHoursApi) {
        return dispatch(fetchHoursForWeekWithHoursApi(sector, hutId, from, to))
      }

      const url = `https://api.pizzahut.io/v1/hut/?sector=${sector}&hutId=${hutId}&includeRegularHours=true&openDays=7`
      const options = {
        headers: {
          'x-feature-role': 'canary',
        },
      }
      const response = await fetch(url, options)
      if (!response.ok) {
        throw new Error('Failed to get hours for the week')
      }

      const json = await response.json()
      return dispatch(fetchHoursForWeekSuccess(sector, hutId, json))
    } catch (error) {
      logger.error({
        msg: 'Failed to fetch hours for week',
      })
      return dispatch(fetchHoursForWeekFailure(sector, hutId, error))
    }
  }
}

export const postHourUpdates = (hoursUpdate) => {
  return async (dispatch, getState) => {
    const {sector, hutId, date} = hoursUpdate

    try {
      const token = idToken(getState())
      const email = attributes(getState()).email
      dispatch(postHoursUpdatesRequest(sector, hutId))
      // TODO add the nice url once it's setup
      const url = `https://0136icutm4.execute-api.eu-west-1.amazonaws.com/dev/commands`
      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token.jwtToken}`},
        body: JSON.stringify(
          Object.assign({}, hoursUpdate, {
            updatedBy: `Admin Tool User: ${email}`,
            fromDate: date,
            toDate: date,
          })
        ),
      }
      const response = await fetch(url, options)
      if (!response.ok) {
        throw new Error(`Failed to update opening hours for store (${hutId})`)
      }

      const json = await response.json()
      return dispatch(postHoursUpdatesSuccess(sector, hutId, json))
    } catch (err) {
      logger.error({
        msg: 'Failed to post hours to API',
        err,
        update: hoursUpdate,
      })
      return dispatch(postHoursUpdatesFailure(sector, hutId, err))
    }
  }
}
