import {groupByDayDate} from '../../store/huts-meta/helpers'
import get from 'lodash/get'
import max from 'lodash/max'
import {
  ADD_TIME_CHANGE,
  REMOVE_TIME_CHANGE,
  RESET_CHANGES,
  FETCH_HOURS_FOR_WEEK_REQUEST,
  FETCH_HOURS_FOR_WEEK_SUCCESS,
  FETCH_HOURS_FOR_WEEK_FAILURE,
  EDIT_TIME_SLOT,
  POST_HOURS_UPDATES_REQUEST,
  POST_HOURS_UPDATES_SUCCESS,
  POST_HOURS_UPDATES_FAILURE
} from './actions'
import {get24HourOpenHour} from 'utils/time'

export const openingHours = (state = {}, action) => {
  switch (action.type) {
    case FETCH_HOURS_FOR_WEEK_REQUEST:
    case FETCH_HOURS_FOR_WEEK_SUCCESS:
    case FETCH_HOURS_FOR_WEEK_FAILURE:
      return {
        ...state,
        ...baseWeekHours(state, action),
      }
    case ADD_TIME_CHANGE:
    case REMOVE_TIME_CHANGE:
    case EDIT_TIME_SLOT:
      return {
        ...state,
        ...editOpeningHours(state, action),
      }
    case POST_HOURS_UPDATES_REQUEST:
      return {
        ...state,
        isPosting: true,
      }
    case POST_HOURS_UPDATES_SUCCESS:
      return {
        ...state,
        isPosting: false,
        error: undefined
      }
    case POST_HOURS_UPDATES_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.error,
      }
    default:
      return state
  }
}

export const baseWeekHours = (
  state = {
    isFetching: false,
    baseWeek: {},
  },
  action
) => {
  switch (action.type) {
    case FETCH_HOURS_FOR_WEEK_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_HOURS_FOR_WEEK_SUCCESS:
      const timeSlots = timeSlotsForTheWeek(action.hut)
      return {
        ...state,
        isFetching: false,
        baseWeek: timeSlots,
        changes: timeSlots,
      }
    case FETCH_HOURS_FOR_WEEK_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    case REMOVE_TIME_CHANGE:
      return {
        ...state,
      }
    default:
      return state
  }
}

// todo: this should be a helper, it's not updating state so should not be in the reducer
export const createId = collection => {
  if (!collection) return 1
  const intArray = Object.keys(collection).map(i => +i)
  if (!intArray.length) return 1
  const id = max(intArray) + 1
  return id
}

export const editOpeningHours = (
  state = {
    changes: {},
  },
  action
) => {
  switch (action.type) {
    case ADD_TIME_CHANGE:
      const id = action.id ? action.id : createId(state[action.hutId].changes[action.date][action.disposition])
      return {
        ...state[action.hutId],
        changes: {
          ...state[action.hutId].changes,
          [action.date]: {
            ...state[action.hutId].changes[action.date],
            [action.disposition]: {
              ...state[action.hutId].changes[action.date][action.disposition],
              [id]: {
                open: action.open,
                close: action.close,
              },
            },
          },
        },
      }
    case EDIT_TIME_SLOT:
      let timeToMerge = {}
      if (action.openOrClose === 'open') {
        timeToMerge.open = action.time
      }
      if (action.openOrClose === 'close') {
        timeToMerge.close = action.time
      }
      return {
        ...state[action.hutId],
        changes: {
          ...state[action.hutId].changes,
          [action.date]: {
            ...state[action.hutId].changes[action.date],
            [action.disposition]: {
              ...state[action.hutId].changes[action.date][action.disposition],
              [action.id]: {
                ...state[action.hutId].changes[action.date][action.disposition][action.id],
                ...timeToMerge,
              },
            },
          },
        },
      }
    case REMOVE_TIME_CHANGE:
      const {[action.id]: value, ...otherValues} = state[action.hutId].changes[action.date][action.disposition]
      return {
        ...state[action.hutId],
        changes: {
          ...state[action.hutId].changes,
          [action.date]: {
            ...state[action.hutId].changes[action.date],
            [action.disposition]: {
              ...otherValues,
            },
          },
        },
      }
    case RESET_CHANGES:
      return {
        ...state,
        changes: {},
      }
    default:
      return state
  }
}

// todo: this should be a helper, it's not updating state so should not be in the reducer
export const timeSlotsForTheWeek = hut => {
  const hours = get(hut, 'hours.physical', undefined)
  if (!hours) return {}
  const groupedHours = groupByDayDate(hours)
  const slots = {}
  Object.keys(groupedHours).forEach(key => {
    const dayHours = groupedHours[key]
    const daySlots = dayHours.reduce(
      (acc, dispositionHours) => {
        acc[dispositionHours.disposition][createId(acc[dispositionHours.disposition])] = {
          open: get24HourOpenHour(dispositionHours.open),
          close: get24HourOpenHour(dispositionHours.close),
        }
        return acc
      },
      {
        delivery: {},
        collection: {},
      }
    )
    slots[key] = daySlots
  })
  return slots
}

export const hours = (state = {}, action) => {
  switch (action.type) {
    case ADD_TIME_CHANGE:
    case REMOVE_TIME_CHANGE:
    case EDIT_TIME_SLOT:
    case FETCH_HOURS_FOR_WEEK_REQUEST:
    case FETCH_HOURS_FOR_WEEK_SUCCESS:
    case FETCH_HOURS_FOR_WEEK_FAILURE:
    case POST_HOURS_UPDATES_REQUEST:
    case POST_HOURS_UPDATES_SUCCESS:
    case POST_HOURS_UPDATES_FAILURE:
    case RESET_CHANGES:
      const hutState = state[action.sector] ? state[action.sector][action.hutId] : state[action.sector];
      return {
        ...state,
        [action.sector]: {
          [action.hutId]: openingHours(hutState, action),
        },
      }
    default:
      return {
        ...state,
      }
  }
}
