import {filterHutsList, hutsStatusList} from '../huts-status/selectors'
import {hutsStatusCollectionClosuresByHutId} from '../huts-collection-closures/selectors'
import {hutsStatusDeliveryClosuresByHutId} from '../huts-delivery-closures/selectors'
import {hutsStatusTotalClosuresByHutId} from '../huts-total-closures/selectors'
import {getSector} from 'store/user/selectors'
import {isClosedForDelivery, isClosedForCollection, isClosed} from '../huts-status/helpers'
import {convertISODurationToMinutes} from 'utils/time'

export const huts = (state) => state.hutsMeta

export const hutsMeta = (state) => {
  const sector = getSector(state)
  const hutsList = huts(state)
  if (!sector || !hutsList) {
    return null
  }
  return hutsList[sector]
}

export const isFetchingAllHutsMetadataForSector = (state) => {
  const hm = hutsMeta(state)
  return hm && hm.isFetching ? hm.isFetching : false
}

export const hutMetaDoesNotExist = (state, hutId) => {
  const hm = hutsMeta(state)
  if (!hm || !hm.list || hm.list.length === 0 || hm.list[hutId]) {
    return false
  }
  return true
}

export const hutsMetaList = (state) => {
  const hm = hutsMeta(state)
  return hm && hm.list ? hm.list : {}
}

export const hutsMetaReceivedAt = (state) => {
  const hm = hutsMeta(state)
  return hm && hm.receivedAt ? hm.receivedAt : 0
}

export const joinMetaAndStatusHuts = (state, status, timePeriodFilter) => {
  const hm = hutsMetaList(state)
  const hs = filterHutsList(hutsStatusList(state), status, timePeriodFilter)

  if (!hm || !hs) return []

  const list = hs.map((hut) => {
    const hutMeta = hm[hut.hutId]
    const row = {
      ...hut,
      ...hutMeta,
    }

    switch (status) {
      case 'partially-closed/collection':
        const hdc = hutsStatusCollectionClosuresByHutId(state, hut.hutId) || {}
        row.downtime = hdc.durationTotal
        row.isClosed = isClosedForCollection(hut)
        break
      case 'partially-closed/delivery':
        const hcc = hutsStatusDeliveryClosuresByHutId(state, hut.hutId) || {}
        row.downtime = hcc.durationTotal
        row.isClosed = isClosedForDelivery(hut)
        break
      case 'closed/manually':
        const htc = hutsStatusTotalClosuresByHutId(state, hut.hutId) || {}
        row.isClosed = isClosed(hut)
        row.downtime = htc.durationTotal
        break
    }
    return row
  })

  if (['closed/manually', 'partially-closed/collection', 'partially-closed/delivery'].includes(status)) {
    list.sort((a, b) => {
      return convertISODurationToMinutes(a.downtime) - convertISODurationToMinutes(b.downtime)
    })
    list.reverse()
  }
  return list
}

export const getHutMeta = (state, hutId) => {
  const hml = hutsMetaList(state)
  return hml[hutId]
}

export const queryHutsMeta = (state, query) => {
  const lc = (val) => `${val}`.toLowerCase()
  const match = (val) => lc(val).indexOf(lc(query)) >= 0
  const hml = hutsMetaList(state)
  return Object.values(hml).filter((i) => {
    return match(i.id) || match(i.name)
  })
}
