import React from 'react'
import {Redirect} from 'react-router'
import {connect} from 'react-redux'
import {isAuthenticated} from 'store/auth/selectors'

class Authenticated extends React.Component {
  render() {
    if ( this.props.isAuthenticated ) {
      return this.props.children
    } else {
      return <Redirect to={this.props.redirect} />
    }
  }
}

export default connect(
  (state,) => ({
    isAuthenticated: isAuthenticated(state),
  })
)(Authenticated)