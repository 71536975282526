import {authRehydrate} from 'store/auth/actions'

export const STORE_INIT = 'STORE_INIT'

export const storeInit = (desiredSector) => {
  return async dispatch => {
    await dispatch({type: STORE_INIT})
    return dispatch(authRehydrate(desiredSector))
  }
}
