import React from 'react'
import Card from './Card'

export default ({orderPayload}) => (
  <Card
    icon={null}
    title={`Transaction | ${orderPayload.customerOrderReference} | ${orderPayload.transactionId}`}
    >
  TraceID: <code>{orderPayload.traceId || 'no traceId found'}</code>
    <br />
    Disposition: {orderPayload.order.disposition.when} {orderPayload.order.disposition.type}
    <br />
    Promised Delivery Time: {orderPayload.order.promisedDeliveryTime}
    {/* <small>
    <a href="https://pizzahut.loggly.com/">Logs</a>
  </small> */}
    {/* <small>
    <a href="#">Find Other Orders</a>
  </small> */}
  </Card>
)