/**
 * Combine all reducers in this file and export the combined reducers.
 */

import {combineReducers} from 'redux'
import {LOCATION_CHANGE} from 'react-router-redux'
import {auth} from 'store/auth/reducers'
import {user} from 'store/user/reducers'
import {flags} from 'store/flag/reducers'
import {reducers as hutReducers} from '../modules/hut-dashboard'
import {reducers as ordersReducers} from '../modules/orders'
const routeInitialState = {
  location: null,
}

function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        location: action.payload,
      })
    default:
      return state
  }
}

export default function createReducer() {
  return combineReducers({
    route: routeReducer,
    auth,
    user,
    flags,
    ...ordersReducers,
    ...hutReducers,
  })
}
