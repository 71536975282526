// @flow

import React from 'react'
import Loadable from 'react-loadable'
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom'
import Loading from 'common/Loading'
import Authenticated from 'common/Auth/Authenticated'
import Header from 'common/Header'
import Menu from 'common/Menu'

import {HutsRoutes} from '../modules/hut-dashboard'
import {OrdersRoutes} from '../modules/orders'

const LoginLoadable = Loadable({
  loader: () => import('routes/login/Login'),
  loading: Loading,
})

const LogoutLoadable = Loadable({
  loader: () => import('routes/logout/Logout'),
  loading: Loading,
})

export const BASE_APP_PATH = '/sector/:sectorId'

export default () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/sector/uk-1/huts" />
        </Route>
        <Route exact path="/login" component={LoginLoadable} />
        <Route exact path="/logout" component={LogoutLoadable} />
        <Authenticated redirect="/login">
          <div className="m-auto text-center h-screen flex flex-col">
            <Header />
            <div className="flex-1 md:flex bg-palette-grey-lightest">
              <Menu />
              <Route path={`${BASE_APP_PATH}/huts`} component={HutsRoutes} />
              <Route path={`${BASE_APP_PATH}/orders`} component={OrdersRoutes} />
            </div>
          </div>
        </Authenticated>
      </Switch>
    </Router>
  )
}
