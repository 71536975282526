import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Card from './Card'
import {faStore} from '@fortawesome/free-solid-svg-icons'

export default ({hut}) => (
  <Card
    icon={<FontAwesomeIcon icon={faStore} className={`text-18 flex-shrink text-palette-amber`} />}
    title="Store"
  >
    {/* todo: link to this hut summary page */}
    {hut.sector}:{hut.id}
    {/* find orders for this hut */}
  </Card>
)