import {getSector} from 'store/user/selectors'
import {getFlag} from 'store/flag/selectors'

export const FETCH_ALL_HUTS_STATUS_REQUEST = 'FETCH_ALL_HUTS_STATUS_REQUEST'
export const FETCH_ALL_HUTS_STATUS_SUCCESS = 'FETCH_ALL_HUTS_STATUS_SUCCESS'
export const FETCH_ALL_HUTS_STATUS_FAILURE = 'FETCH_ALL_HUTS_STATUS_FAILURE'

export const FETCH_HUT_STATUS_REQUEST = 'FETCH_HUT_STATUS_REQUEST'
export const FETCH_HUT_STATUS_SUCCESS = 'FETCH_HUT_STATUS_SUCCESS'
export const FETCH_HUT_STATUS_FAILURE = 'FETCH_HUT_STATUS_FAILURE'

export const fetchAllHutsStatusBySectorRequest = (sector) => {
  return {
    type: FETCH_ALL_HUTS_STATUS_REQUEST,
    sector,
  }
}

export const fetchAllHutsStatusBySectorSuccess = (sector, json) => {
  return {
    type: FETCH_ALL_HUTS_STATUS_SUCCESS,
    sector,
    list: json.data,
    receivedAt: Date.now(),
  }
}

export const fetchAllHutsStatusBySectorFailure = (sector, error) => {
  return {
    type: FETCH_ALL_HUTS_STATUS_FAILURE,
    sector,
    error,
  }
}

export const fetchHutStatusBySectorAndHutIdRequest = (sector, hutId) => {
  return {
    type: FETCH_HUT_STATUS_REQUEST,
    sector,
    hutId,
  }
}

export const fetchHutStatusBySectorAndHutIdSuccess = (sector, hutId, json) => {
  return {
    type: FETCH_HUT_STATUS_SUCCESS,
    sector,
    hutId,
    status: json,
    receivedAt: Date.now(),
  }
}

export const fetchHutStatusBySectorAndHutIdFailure = (sector, hutId, error) => {
  return {
    type: FETCH_HUT_STATUS_FAILURE,
    sector,
    hutId,
    error,
  }
}

export const fetchAllHutsStatusBySector = (sector, featureRole) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAllHutsStatusBySectorRequest(sector))
      const url = `https://api.pizzahut.io/v2/huts/status/${sector}`
      const options = {
        headers: {
          'x-feature-role': featureRole || 'none',
        },
      }
      const result = await fetch(url, options)
      const json = await result.json()
      return dispatch(fetchAllHutsStatusBySectorSuccess(sector, json))
    } catch (error) {
      return dispatch(fetchAllHutsStatusBySectorFailure(sector, error))
    }
  }
}

export const fetchHutStatusBySectorAndHutId = (sector, hutId, featureRole) => {
  return async (dispatch) => {
    try {
      dispatch(fetchHutStatusBySectorAndHutIdRequest(sector, hutId))
      const url = `https://api.pizzahut.io/v2/huts/status/${sector}/${hutId}`
      const options = {
        headers: {
          'x-feature-role': featureRole || 'none',
        },
      }
      const result = await fetch(url, options)
      const json = await result.json()
      return dispatch(fetchHutStatusBySectorAndHutIdSuccess(sector, hutId, json))
    } catch (error) {
      return dispatch(fetchHutStatusBySectorAndHutIdFailure(sector, hutId, error))
    }
  }
}

export const fetchAllHutsStatus = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const sector = getSector(state)
    const featureRole = getFlag(state, 'featureRole')

    return dispatch(fetchAllHutsStatusBySector(sector, featureRole))
  }
}

export const fetchHutStatus = (hutId) => {
  return async (dispatch, getState) => {
    if (!hutId) {
      throw new Error('Unable to fetch hut status: missing hutId')
    }

    const state = getState()
    const sector = getSector(state)
    const featureRole = getFlag(state, 'featureRole')

    return dispatch(fetchHutStatusBySectorAndHutId(sector, hutId, featureRole))
  }
}
