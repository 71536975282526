import logger from 'utils/logger'

export function getFlag(state, flag) { 
    if (!state.flags) {
        logger.warn('attempt to get flag before flags have been loaded')
    }
    return state.flags && (state.flags.overrides[flag] || state.flags.defaults[flag])
}

export function getAll(state) { 
    return {
        ...state.flags.defaults,
        ...state.flags.overrides,
    }
}