import {getSector} from 'store/user/selectors'
import {getFlag} from 'store/flag/selectors'

export const FETCH_HUT_META_REQUEST = 'FETCH_HUT_META_REQUEST'
export const FETCH_HUT_META_SUCCESS = 'FETCH_HUT_META_SUCCESS'
export const FETCH_HUT_META_FAILURE = 'FETCH_HUT_META_FAILURE'

export const fetchHutMetadataRequest = (sector, hutId) => {
  return {
    type: FETCH_HUT_META_REQUEST,
    sector,
    hutId,
  }
}

export const fetchHutMetadataSuccess = (sector, hutId, json) => {
  return {
    type: FETCH_HUT_META_SUCCESS,
    sector,
    hut: json,
    hutId,
  }
}

export const fetchHutMetadataFailure = (sector, hutId, error) => {
  return {
    type: FETCH_HUT_META_FAILURE,
    sector,
    error,
    hutId,
  }
}

export const fetchHutMetadataBySectorAndHutId = (sector, hutId, featureRole) => {
  return async (dispatch) => {
    try {
      dispatch(fetchHutMetadataRequest(sector, hutId))
      const url = `https://api.pizzahut.io/v1/hut/?sector=${sector}&hutId=${hutId}&includeRegularHours=true&openDays=7`
      const options = {
        headers: {
          'x-feature-role': featureRole || 'none',
        },
      }
      const result = await fetch(url, options)
      const json = await result.json()
      return dispatch(fetchHutMetadataSuccess(sector, hutId, json))
    } catch (error) {
      return dispatch(fetchHutMetadataFailure(sector, hutId, error))
    }
  }
}

export const fetchHutMetadata = (hutId) => {
  return async (dispatch, getState) => {
    if (!hutId) {
      throw new Error('Unable to fetch hut status: missing hutId')
    }

    const state = getState()
    const sector = getSector(state)
    const featureRole = getFlag(state, 'featureRole')

    return dispatch(fetchHutMetadataBySectorAndHutId(sector, hutId, featureRole))
  }
}
