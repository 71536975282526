/**
 * Create the store with dynamic reducers
 */

import {createStore, applyMiddleware, compose} from 'redux'
import {routerMiddleware} from 'react-router-redux'
import createReducer from './root-reducer'
import thunk from 'redux-thunk'
import {storeInit} from 'store/actions'
import {getSectorFromURL} from 'utils/location'

export default function configureStore(initialState = {}, history) {
  // routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [routerMiddleware(history), thunk]

  const enhancers = [applyMiddleware(...middlewares)]

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false, 
        })
      : compose
  /* eslint-enable */

  const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers))

  // Extensions
  store.injectedReducers = {} // Reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./root-reducer', () => {
      store.replaceReducer(createReducer(store.injectedReducers))
      store.dispatch({type: '@@REDUCER_INJECTED'})
    })
  }

  const desiredSector = getSectorFromURL()
  store.dispatch(storeInit(desiredSector))

  return store
}
