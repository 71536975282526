import React from 'react'
import Card from './Card'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser} from '@fortawesome/free-solid-svg-icons'

export default ({customer}) => (
  <Card
    icon={<FontAwesomeIcon icon={faUser} className={`text-18 flex-shrink text-palette-amber`} />}
    title="Customer"
    >
    {customer.name}
    <br />
    {customer.email}
    {/* This is horribly hacky don't hate me */}
    {customer.deliveryAddress.lines && <br />}
    {customer.deliveryAddress.lines && customer.deliveryAddress.lines.map(l => (<span>l</span>))}
    {customer.deliveryAddress.interior && <br />}
    {customer.deliveryAddress.interior}
    {customer.deliveryAddress.notes && <br />}
    {customer.deliveryAddress.notes}
    {customer.deliveryAddress.address && <br />}
    {customer.deliveryAddress.address}
    <br />
    <a href={`tel:${customer.phone}`}>{customer.phone}</a>
  </Card>
)