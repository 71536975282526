import {FETCH_HUT_META_REQUEST, FETCH_HUT_META_SUCCESS, FETCH_HUT_META_FAILURE} from './actions'

const initialHutMetadataState = {
  isFetching: false,
}

export const hutMetaHut = (state = initialHutMetadataState, action) => {
  switch (action.type) {
    case FETCH_HUT_META_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_HUT_META_SUCCESS:
      return {
        ...state,
        [action.hutId]: action.hut,
        isFetching: false,
      }
    case FETCH_HUT_META_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}

export const hutMeta = (state = {}, action) => {
  switch (action.type) {
    case FETCH_HUT_META_REQUEST:
    case FETCH_HUT_META_SUCCESS:
    case FETCH_HUT_META_FAILURE:
      return {
        ...state,
        [action.sector]: hutMetaHut(state[action.sector], action),
      }
    default:
      return state
  }
}
