import React from 'react'
import PropTypes from 'prop-types'
import SearchResult from 'common/SearchResult'

export const propTypes = {
  items: PropTypes.arrayOf(SearchResult.propTypes.item),
  onClosed: PropTypes.func,
  onItemSelected: PropTypes.func,
  baseItemPath: PropTypes.string,
}

class SearchResultsList extends React.Component {
  state = {
    focusIndex: -1,
  }

  boundHandleKeyDown = this.handleKeyDown.bind(this)

  componentDidMount() {
    document.addEventListener('keydown', this.boundHandleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.boundHandleKeyDown)
  }

  onArrowPress(step) {
    let {items} = this.props
    let {focusIndex} = this.state
    let newFocusIndex = focusIndex + step
    if (newFocusIndex < 0) {
      newFocusIndex = items.length - 1
    } else if (newFocusIndex > items.length - 1) {
      newFocusIndex = 0
    }
    this.setState({
      focusIndex: newFocusIndex,
    })
  }

  handleKeyDown(evt) {
    switch (evt.keyCode) {
      case 27: // escape
        this.props.onClosed()
        break
      case 38: // up
        evt.preventDefault() // stop the cursor jumping to start of input
        this.onArrowPress(-1)
        break
      case 40: // down
        evt.preventDefault() // stop the cursor jumping to end of input
        this.onArrowPress(1)
        break
      default:
        break
    }
  }

  render() {
    const {items, onItemSelected, baseItemPath} = this.props
    return (
      <div className="relative">
        <ul className="absolute w-full bg-white border shadow-medium -mt-px overflow-hidden z-1">
          {items.map((item, index) => (
            <li key={index}>
              <SearchResult
                item={item}
                isFocussed={this.state.focusIndex === index}
                onSelected={(item) => onItemSelected(item)}
                baseItemPath={baseItemPath}
              />
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

SearchResultsList.propTypes = propTypes

export default SearchResultsList
