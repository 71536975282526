import traceId from 'utils/traceId'

const _global = window || global || {}

const pushToLoggly = level => (data = {}) => {
  /* istanbul ignore if */
  if (
    data !== null &&
    data.hasOwnProperty('error') &&
    data.error instanceof Error &&
    !data.hasOwnProperty('errorAsString')
  ) {
    data.errorAsString = data.error.toString()
  }

  const {msg, ...dataWithoutMsg} = data || {}

  if (typeof _global._LTracker !== 'undefined') {
    _global._LTracker.push({
      level,
      name: 'toolshed',
      timestamp: new Date().toISOString(),
      data: dataWithoutMsg,
      msg,
      traceId,
    })
  }

  if (process.env.NODE_ENV !== 'test') console.log(data)
}

const levelByLogFunctionName = {
  fatal: 60,
  error: 50,
  warn: 40,
  info: 30,
  debug: 20,
  trace: 10,
}

const logger = Object.keys(levelByLogFunctionName).reduce((logger, logFunctionName) => {
  const level = levelByLogFunctionName[logFunctionName]
  logger[logFunctionName] = pushToLoggly(level)
  return logger
}, {})

// Modified from /node_modules/loggly-jslogger/src/loggly.tracker.js
// Send console error messages to Loggly, with traceId
var _onerror = window.onerror
window.onerror = function(msg, url, line, col) {
  if (typeof _LTracker !== 'undefined') {
    _global._LTracker.push({
      category: 'BrowserJsException',
      exception: {
        message: msg,
        url: url,
        lineno: line,
        colno: col,
      },
      traceId,
    })

    if (_onerror && typeof _onerror === 'function') {
      _onerror.apply(window, arguments)
    }
  }
}

export default logger
