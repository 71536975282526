import logger from 'utils/logger'
import supportsLocalStorage from 'utils/supportsLocalStorage'

export function assertLocalStorage() {
  if (!supportsLocalStorage()) {
    logger.warn('localStorage not supported')
    return false
  }
  return true
}

export function storeCredentials(payload) {
  if (assertLocalStorage()) {
    localStorage.setItem('auth', JSON.stringify(payload))
  }
}

export function retrieveCredentials() {
  if (assertLocalStorage()) {
    const data = localStorage.getItem('auth')
    try {
      return JSON.parse(data)
    }
    catch (e) {
      logger.warn('error parsing auth json from localStorage', {data})
    }
  }
}

export function removeCredentials() {
  if (assertLocalStorage()) {
    localStorage.removeItem('auth')
  }
}
