import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserCircle} from '@fortawesome/free-solid-svg-icons'

import {getSector} from 'store/user/selectors'
import {attributes} from 'store/auth/selectors'

const ActiveAccount = ({sector, account}) => {
  return (
    <div
      className={`flex flex-wrap items-center justify-between border border-solid border border-grey-lightest text-grey w-full p-15`}
    >
      <div className="w-full pb-10">
        <FontAwesomeIcon className="text-64 text-grey text-6xl" icon={faUserCircle} fixedWidth />
      </div>

      <div className="w-full">
        <span className="block">{account.email}</span>
        <span className="block">
          <strong className="font-bold">Active Sector: </strong>
          <span className="uppercase">{sector}</span>
        </span>
      </div>
    </div>
  )
}

ActiveAccount.propTypes = {
  account: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  sector: PropTypes.string,
}

export default connect((state) => ({
  sector: getSector(state),
  account: attributes(state),
}))(ActiveAccount)
