export const SET_FLAG = 'SET_FLAG'
export const RESET_FLAGS = 'RESET_FLAGS'

export const setFlag = (flag, value) => {
  return {
    type: SET_FLAG,
    flag,
    value,
  }
}

export const resetFlags = () => {
  return {
    type: RESET_FLAGS,
  }
}