import {idToken} from 'store/auth/selectors'

export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST'
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS'
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE'

export const fetchOrderRequest = (transactionId) => {
  return {
    type: FETCH_ORDER_REQUEST,
    transactionId,
  }
}

export const fetchOrderSuccess = (transactionId, json) => {
  return {
    type: FETCH_ORDER_SUCCESS,
    transactionId,
    order: json,
  }
}

export const fetchOrderFailure = (transactionId, error) => {
  return {
    type: FETCH_ORDER_FAILURE,
    transactionId,
    error,
  }
}

export const fetchOrder = (transactionId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchOrderRequest(transactionId))

      const token = idToken(getState())

      const url = `https://api.pizzahut.io/v1/order-debugger/?term=${transactionId}`
      const options = {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token.jwtToken}`,
        },
      }
      const result = await fetch(url, options)
      const json = await result.json()
      return dispatch(fetchOrderSuccess(transactionId, json))
    } catch (error) {
      return dispatch(fetchOrderFailure(transactionId, error))
    }
  }
}
