import moment from 'moment-timezone'
import memoize from 'lodash/memoize'
const dateAndTimeFormat = 'lll'

export const getTimeByTimezone = (timestamp, timezone = 'Europe/London') => {
  return moment.tz(timestamp, timezone).format(dateAndTimeFormat)
}

export const get24HourOpenHour = date => {
  return moment(date).format('HH:mm')
}

export const isSameDay = (day1, day2) => {
  return moment(day1).isSame(day2, 'day')
}

export const from = timePeriod => {
  switch (timePeriod) {
    case '7days':
      return moment(moment().subtract(7, 'days')).format('YYYY-MM-DD')
    default:
      return moment().format('YYYY-MM-DD')
  }
}

export const isDateToday = date => {
  return date.isSame(today(), 'd')
}

export const today = () => moment().startOf('day')

export const isDateAfterToday = date => {
  return date.isAfter(moment())
}

export const timeSlots = memoize(() => {
  const timeRange = {
    from: 6,
    to: 6,
  }
  // 06:00 - 06:00 ---> length of day in pizza hut world 
  return [
    ...createTimeRange(timeRange.from, 24, 5),
    ...createTimeRange(0, timeRange.to, 5),
    formatTime(6, 0),
  ]
})

const formatTime = (hour, minutes) => `${pad(hour, 2)}:${pad(minutes, 2)}`

export const createTimeRange = (startHour, endHour, minutesInterval) => {
  const range = []
  for (let hour = startHour; hour < endHour; hour++) {
    for (let minutes = 0; minutes < 60; minutes += minutesInterval) {
      range.push(formatTime(hour, minutes))
    }
  }
  return range
}

export const pad = (n, width, z) => {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export const to = () => {
  return moment().format('YYYY-MM-DD')
}

export const convertMinsToHrsMins = mins => {
  let h = Math.floor(mins / 60)
  let m = Math.round(mins % 60, 2)
  h = h < 10 ? '0' + h : h
  m = m < 10 ? '0' + m : m
  return `${h} hrs, ${m} mins`
}

export const dayShortCode = date => {
  return date.format('ddd')
}

export const dayNumber = date => {
  return date.format('D')
}

export const dayName = date => {
  return date.format('dddd')
}

export const convertISODurationToMinutes = durationISO => {
  return moment.duration(durationISO).asMinutes()
}

export const convertISODurationToSeconds = durationISO => {
  return moment.duration(durationISO).asSeconds()
}

export const convertISODurationToFormattedHoursMins = durationISO => {
  return convertMinsToHrsMins(convertISODurationToMinutes(durationISO))
}

export const convertISODurationToFormattedSeconds = durationISO => {
  return `${convertISODurationToSeconds(durationISO)} seconds`
}

export const convertISODurationToFormatted = durationISO => {
  const minutes = convertISODurationToMinutes(durationISO)
  if (minutes < 1) {
    return convertISODurationToFormattedSeconds(durationISO)
  }
  return convertMinsToHrsMins(minutes)
}

export const startOfWeek = () => {
  return moment().startOf('isoWeek')
}

export const getCurrentWeekDates = () => {
  return getWeekDates(startOfWeek())
}

export const getDates = (startMoment, datesToCountForward) => {
  const days = []
  let day = startMoment
  const endMoment = moment(startMoment).add(datesToCountForward, 'days')
  while (day <= endMoment) {
    days.push(day)
    day = day.clone().add(1, 'd')
  }
  return days
}

export const getWeekDates = startOfWeek => {
  return getDates(startOfWeek, 6)
}

export const isYearWeekValid = ({year, week, day}) => {
  if (!year && !week) {
    return false
  }
  return moment(year)
    .add(week, 'weeks')
    .add(day, 'days')
    .isValid()
}

export const currentYearWeekAndDay = () => {
  return {
    year: moment().isoWeekYear(),
    week: moment().isoWeek(),
    day: moment().isoWeekday(),
  }
}

export const yearWeekAndDay = date => {
  return {
    year: date.isoWeekYear(),
    week: date.isoWeek(),
    day: date.isoWeekday(),
  }
}

export const dateFromYearWeekAndDay = ({year, week, day}) => {
  return moment(`${year}-W${pad(week, 2, '0')}-${day}`)
}

export const now = () => new Date()

export function formatWeek(weekStart) {
  const weekEnd = moment(weekStart).add(1, 'week').add(-1, 'day')

  return `${weekStart.date()}-${weekEnd.date()} ${weekStart.format('MMMM')} '${weekStart.format('YY')}`
}

export function getWeekDays(date) {
  const days = [date]
  const thisWeek = date.isoWeek()

  let nextday = moment(date).add(1, 'day')
  while ( nextday.isoWeek() === thisWeek) {
    days.push(moment(nextday))
    nextday.add(1, 'day')
  }

  let previousDay = moment(date).add(-1, 'day')
  while ( previousDay.isoWeek() === thisWeek) {
    days.unshift(moment(previousDay))
    previousDay = previousDay.add(-1, 'day')
  }

  return days
}