import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT_SUCCESS,
  AUTH_REHYDRATE} from './actions'

const defaultState = {
  authentication: null,
  attributes: null,
}

export const auth = (state = {}, action) => {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
      }
    case AUTH_REHYDRATE:
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case AUTH_LOGIN_FAILURE:
      return {
        ...state,
      }
    case AUTH_LOGOUT_SUCCESS:
      return {
        ...defaultState,
      }
    default:
      return {
        ...defaultState,
        ...state,
      }
  }
}
