import Auth from '@aws-amplify/auth'
import get from 'lodash/get'
import noop from 'lodash/noop'
import logger from 'utils/logger'

const AWS_COGNITO_REGION = 'eu-west-1'
const UK_AWS_COGNITO_USER_POOL = 'eu-west-1_qp53x0idB'
const UK_AWS_COGNITO_CLIENT_ID = '3jf0ts50smkcppu903l4ro1bkb'

export const getAuth = () => {
  Auth.configure({
    // REQUIRED - Amazon Cognito Region
    region: AWS_COGNITO_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: UK_AWS_COGNITO_USER_POOL,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: UK_AWS_COGNITO_CLIENT_ID,
  })
  return Auth
}

export const login = ({dispatchCustomerAuthenticated = noop, loginPayload}) => {
  return getAuth().signIn(loginPayload.email, loginPayload.password)
    .then(response => {
      logger.info({
        msg: 'AUTHENTICATION_EVENT: User authenticated successfully',
        userId: get(response, 'signInUserSession.idToken.payload.cognito:username'),
      })

      const data = {
        authentication: {
          token: {
            idToken: {
              jwtToken: get(response, 'signInUserSession.idToken.jwtToken'),
              payload: get(response, 'signInUserSession.idToken.payload'),
            },
            accessToken: {
              jwtToken: get(response, 'signInUserSession.accessToken.jwtToken'),
              payload: get(response, 'signInUserSession.accessToken.payload'),
            },
            refreshToken: {
              token: get(response, 'signInUserSession.refreshToken.token'),
            },
          },
          tokenProvider: 'AWS_COGNITO',
        },
      }

      dispatchCustomerAuthenticated(data)
      return data
    })
    .catch(err => {
      logger.info({
        msg: `AUTHENTICATION_EVENT: User authentication failed ${err.message}`,
        userId: loginPayload.email,
      })
      throw err
    })
}

export const signUp = ({dispatchCustomerAuthenticated = noop, login, signUpPayload}) => {
  return getAuth().signUp({
    username: signUpPayload.email,
    password: signUpPayload.password,
    SignupAttributes: {
      email: signUpPayload.email,
    },
  }).then(() => {
    logger.info({
      msg: 'AUTHENTICATION_EVENT: New user sign up completed successfully',
    })
    login({dispatchCustomerAuthenticated, loginPayload: signUpPayload})
  })
}

export const forgotPasswordRequest = ({forgotPasswordPayload}) => {
  return getAuth().forgotPassword(forgotPasswordPayload.email).then(() => {
    logger.info({
      msg: 'AUTHENTICATION_EVENT: Password reset code requested',
    })
  })
}

export const forgotPasswordSubmit = ({dispatchCustomerAuthenticated = noop, forgotPasswordPayload, login}) => {
  return getAuth().forgotPasswordSubmit(
    forgotPasswordPayload.email,
    forgotPasswordPayload.resetCode,
    forgotPasswordPayload.password
  ).then(() => {
    logger.info({
      msg: 'AUTHENTICATION_EVENT: Password reset completed successfully',
    })
    return login({dispatchCustomerAuthenticated, loginPayload: forgotPasswordPayload})
  })
}

export const logout = ({dispatchCustomerLogout = noop}) => {
  return getAuth().signOut({global: true})
    .then(() => {
      dispatchCustomerLogout()
      logger.info({
        msg: 'AUTHENTICATION_EVENT: User logout completed successfully',
      })
    })
    .catch(e => {
      logger.error(e)
    })
}

export const isAuthenticated = ({dispatchCustomerAuthenticated = noop}) => {
  return getAuth().currentSession()
    .then(response => {
      logger.info({
        msg: 'AUTHENTICATION_EVENT: User re-authenticated successfully using existing token',
        userId: get(response, 'idToken.payload.cognito:username'),
      })
      const authentication = {
        token: {
          idToken: {
            jwtToken: get(response, 'idToken.jwtToken'),
            payload: get(response, 'idToken.payload'),
          },
          accessToken: {
            jwtToken: get(response, 'accessToken.jwtToken'),
            payload: get(response, 'accessToken.payload'),
          },
          refreshToken: {
            token: get(response, 'refreshToken.token'),
          },
        },
        tokenProvider: 'AWS_COGNITO',
      }
      dispatchCustomerAuthenticated({authentication})
      return authentication
    })
    .catch(e => {
      logger.error(e)
    })
}

export const currentUserInfo = () => {
  return getAuth().currentUserInfo()
}

export default getAuth
