import {statusRoutes} from '../../routes/StatusRoutes'
import get from 'lodash/get'

export const addCountsToStatusRoutes = countsMapping => {
  return statusRoutes.map(statusRoute => {
    statusRoute.count = countsMapping[statusRoute.route]
    return statusRoute
  })
}

export const filterStatusRoutesByParent = (statusRoutes, parent) => {
  return statusRoutes.filter(statusRoute => {
    // routes can have/delimited/parts by '/'
    const statusRouteParts = statusRoute.route.split('/')
    if (!statusRouteParts.includes(parent)) return false
    if (statusRouteParts[0] !== parent) return false
    return true
  })
}

export const getStatusRoute = (status) => {
  return statusRoutes.find(statusRoute => statusRoute.route === status)
}

export const parentRoute = (statusRoutes, parent) => {
  return filterStatusRoutesByParent(statusRoutes, parent).filter(statusRoute => {
    if (statusRoute.route !== parent) return false
    return true
  })[0]
}

export const childRoutes = (statusRoutes, parent) => {
  return filterStatusRoutesByParent(statusRoutes, parent).filter(statusRoute => {
    if (statusRoute.route === parent) return false
    return true
  })
}

export const getParent = route => {
  return route.split('/')[0]
}

export const isPosUnhealthy = hut => {
  const pos = get(hut, 'overrides.pos', [])
  return pos[pos.length - 1] && pos[pos.length - 1].state === 'UNHEALTHY'
}

export const isClosedForDelivery = (hut) => {
  return get(hut, 'summary.web.scheduled.delivery') === 'UNAVAILABLE_CLOSED' && get(hut, 'summary.web.scheduled.collection') !== 'UNAVAILABLE_CLOSED'
}

export const isClosedForCollection = (hut) => {
  return get(hut, 'summary.web.scheduled.delivery') !== 'UNAVAILABLE_CLOSED' && get(hut, 'summary.web.scheduled.collection') === 'UNAVAILABLE_CLOSED'
}

export const isClosed = (hut) => {
  const wco = get(hut, 'overrides.web.collection', [])
  const wdo = get(hut, 'overrides.web.delivery', [])
  const isWebCollectionOff = wco[wco.length - 1] && wco[wco.length - 1].state === 'OFF'
  const isWebDeliveryOff = wdo[wdo.length - 1] && wdo[wdo.length - 1].state === 'OFF'
  return isWebCollectionOff && isWebDeliveryOff
}
