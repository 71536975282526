import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import MenuItems from 'common/MenuItems'
import ActiveAccount from 'common/ActiveAccount'

export class ToggleMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuOpen: false,
    }
  }

  toggleMenu() {
    const {isMenuOpen} = this.state
    this.setState({isMenuOpen: !isMenuOpen})
  }

  getMenu() {
    const {isMenuOpen} = this.state

    return (
      <div
        className={`w-80p animated fastest menu-panel h-screen bg-palette-grey-lightest fixed top-0 left-0 text-white ${
          isMenuOpen ? 'menu-panel--open' : 'menu-panel--close'
        }`}
      >
        <div className="text-right">
          <button className="mt-10 mr-20" onClick={() => this.toggleMenu()}>
            <FontAwesomeIcon className="text-grey text-24" icon="times" />
          </button>
        </div>

        <ActiveAccount />
        <MenuItems />
      </div>
    )
  }

  render() {
    const {isMenuOpen} = this.state

    return (
      <div className="md:hidden">
        <button
          onClick={() => this.toggleMenu()}
          className={isMenuOpen ? 'is-active' : ''}
          type="button"
        >
          <FontAwesomeIcon className="text-grey text-24 ml-20" icon="bars" />
        </button>
        <button
          onClick={() => this.toggleMenu()}
          className={`w-full animated fastest menu-background h-screen bg-black-30pct fixed top-0 left-0 text-white ${
            isMenuOpen ? 'menu-background--show menu-panel--show' : 'menu-background--hide menu-panel--close'
          }`}
        />
        {this.getMenu()}
      </div>
    )
  }
}

export default ToggleMenu
