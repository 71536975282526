import configuration from './featureFlags'
import {SET_FLAG, RESET_FLAGS} from './actions'


export const setFlag = (state, action) => {
  return {
    ...state,
    overrides: {
      ...state.overrides,
      [action.flag]: action.value,
    },
  }
}
const defaultState = {
  defaults: configuration,
  overrides: {},
}

export const flags = (state = defaultState, action) => {
  switch (action.type) {
    case RESET_FLAGS: 
      return defaultState
    case SET_FLAG: return setFlag(state, action)
    case '@@INIT':
      return {
        defaults: configuration,
        overrides: state.overrides,
      }
    default:
      return state
  }
}