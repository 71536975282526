import {
  FETCH_REGULAR_HOURS_FOR_HUT_IN_PROGRESS,
  FETCH_REGULAR_HOURS_FOR_HUT_SUCCESS,
  FETCH_REGULAR_HOURS_FOR_HUT_FAILURE,
} from './actions'

const splitHoursByType = (regularHoursByDay) => {
  return regularHoursByDay.map((regularHoursForDay) => {
    return {
      day: regularHoursForDay.day,
      hutId: regularHoursForDay.hutId,
      sector: regularHoursForDay.sector,
      hours: {
        DELIVERY: regularHoursForDay.hours.filter((slot) => slot.disposition === 'DELIVERY'),
        COLLECTION: regularHoursForDay.hours.filter((slot) => slot.disposition === 'COLLECTION'),
      },
    }
  })
}

const initialState = {
  isFetching: false,
}

export const regularHours = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REGULAR_HOURS_FOR_HUT_SUCCESS:
      return {
        isFetching: false,
        sector: action.sector,
        hutId: action.hutId,
        regularHoursByDay: splitHoursByType(action.regularHoursByDay),
      }
    case FETCH_REGULAR_HOURS_FOR_HUT_IN_PROGRESS:
      return {
        isFetching: true,
      }
    case FETCH_REGULAR_HOURS_FOR_HUT_FAILURE:
      return {
        isFetching: false,
      }
    default:
      return {
        ...state,
      }
  }
}
