import {idToken} from 'store/auth/selectors'

export const REQUEST_SET_CREDENTIALS = 'REQUEST_SET_CREDENTIALS'
export const SET_CREDENTIALS_SUCCESS = 'SET_CREDENTIALS_SUCCESS'
export const SET_CREDENTIALS_ERROR = 'SET_CREDENTIALS_ERROR'

export const requestSetCredentials = () => {
  return {
    type: REQUEST_SET_CREDENTIALS,
  }
}

export const setCredentialsSuccess = () => {
  return {
    type: SET_CREDENTIALS_SUCCESS,
  }
}

export const setCredentialsFailure = error => {
  return {
    type: SET_CREDENTIALS_ERROR,
    error,
  }
}

export const SetCredentials = ({username, hutId, password, passphrase, addNew}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(requestSetCredentials())
      const token = idToken(getState())

      const url = `https://api.pizzahut.io/v1/payment-providers/credentials`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.jwtToken}`,
        },
        method: 'PUT',
        body: JSON.stringify({
          type: 'hipay',
          updateInput: [
            {
              storeId: hutId,
              password,
              passphrase,
              username,
              addNew,
            },
          ],
        }),
      }
      const result = await fetch(url, options)
      if (result.status !== 200) {
        return dispatch(setCredentialsFailure(`Failed to update credentials.`))
      } else {
        return dispatch(setCredentialsSuccess())
      }
    } catch (error) {
      console.error(error)
      return dispatch(setCredentialsFailure(`Failed to update credentials: ${error}`))
    }
  }
}
