import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Card from './Card'
import {faCashRegister} from '@fortawesome/free-solid-svg-icons'

export default ({hut, posResponse}) => (
  <Card
    icon={<FontAwesomeIcon icon={faCashRegister} className={`text-18 flex-shrink text-palette-amber`} />}
    title="Point of Sale"
  >
    POS: {hut.pos.type}
    URI: <code>{hut.pos.uri}</code>
    <br />
    POS Response: {posResponse.ok ? 'ok' : 'failed'}
  </Card>)