import supportsLocalStorage from 'utils/supportsLocalStorage'

export const guid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

export function getTraceId() {
  if (supportsLocalStorage()) {
    const KEY = 'traceId'
    // try to load
    let key = localStorage.getItem(KEY)
    if (!key) {
      key = guid()
      localStorage.setItem(KEY, key)
    }
    return key
  } else {
    return guid()
  }
}

export default getTraceId()
