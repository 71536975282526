import {getSector, userPreferences} from 'store/user/selectors'
import {batch} from 'react-redux'

import {from, to} from 'utils/time'

export const FETCH_HUTS_POS_DOWNTIME_REQUEST = 'FETCH_HUTS_POS_DOWNTIME_REQUEST'
export const FETCH_HUTS_POS_DOWNTIME_SUCCESS = 'FETCH_HUTS_POS_DOWNTIME_SUCCESS'
export const FETCH_HUTS_POS_DOWNTIME_FAILURE = 'FETCH_HUTS_POS_DOWNTIME_FAILURE'

export const fetchHutsPosDowntimeRequest = (sector) => {
  return {
    type: FETCH_HUTS_POS_DOWNTIME_REQUEST,
    sector,
  }
}

export const fetchHutsPosDowntimeSuccess = (sector, json) => {
  return {
    type: FETCH_HUTS_POS_DOWNTIME_SUCCESS,
    sector,
    list: json.data,
    receivedAt: Date.now(),
  }
}

export const fetchHutsPosDowntimeFailure = (sector, error) => {
  return {
    type: FETCH_HUTS_POS_DOWNTIME_FAILURE,
    sector,
    error,
  }
}

export const fetchHutsPosDowntimeBySector = (sector, from, to) => {
  return async (dispatch) => {
    try {
      dispatch(fetchHutsPosDowntimeRequest(sector))
      const url = `https://api.pizzahut.io/v2/huts/status/${sector}/reports/pos-connectivity?from=${from}&to=${to}&filterDurationsLessThan=PT3M`
      const result = await fetch(url)
      const json = await result.json()

      return dispatch(fetchHutsPosDowntimeSuccess(sector, json))
    } catch (error) {
      return dispatch(fetchHutsPosDowntimeFailure(sector, error))
    }
  }
}

export const fetchHutsPosDowntime = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const sector = getSector(state)
    const preferences = userPreferences(state)

    return dispatch(fetchHutsPosDowntimeBySector(sector, from(preferences.filter.timePeriod), to()))
  }
}
