import get from 'lodash/get'
import {getSector} from 'store/user/selectors'
import {isClosed, isClosedForDelivery, isClosedForCollection} from './helpers'
import {joinMetaAndStatusHuts} from '../../store/huts-meta/selectors'
import {joinMetaPosDowntimeHuts} from '../../store/huts-pos-downtime/selectors'


export const huts = state => state.hutsStatus

const hutHasDelivery = hut => hut.sectorId !== 'uk-2'

export const hutsStatus = state => {
  const sector = getSector(state)
  return huts(state)[sector]
}

export const isFetchingHutsStatusForSector = state => {
  const hs = hutsStatus(state)
  return hs && hs.isFetching ? hs.isFetching : false
}

export const hutStatusDoesNotExist = (state, hutId) => {
  const hs = hutsStatus(state)
  if (!hs || !hs.list || hs.list.length === 0 || hs.list.find(h => h.hutId === hutId)) {
    return false
  }
  return true
}

export const hutsStatusList = state => {
  const hs = hutsStatus(state)
  return hs && hs.list ? hs.list : []
}

export const hutsStatusReceivedAt = state => {
  const hs = hutsStatus(state)
  return hs && hs.receivedAt ? hs.receivedAt : 0
}

const openForDelivery = hut => {
  return get(hut, 'summary.web.asap.delivery') === 'AVAILABLE'
}

const openForCollection = hut => {
  return get(hut, 'summary.web.asap.collection') === 'AVAILABLE'
}

const fullyOpen = hut => {
  return openForDelivery(hut) && (!hutHasDelivery(hut) || openForCollection(hut))
}

export const filterByFullyOpen = hutsStatusList => {
  return hutsStatusList.filter(fullyOpen)
}

export const filterByCollectionClosed = hutsStatusList => {
  return hutsStatusList.filter(isClosedForCollection)
}

export const filterByDeliveryClosed = hutsStatusList => {
  return hutsStatusList.filter(isClosedForDelivery)
}

export const filterByClosed = hutsStatusList => {
  return hutsStatusList.filter(
    hut =>
      get(hut, 'summary.web.asap.delivery') !== 'AVAILABLE' && get(hut, 'summary.web.asap.collection') !== 'AVAILABLE'
  )
}

export const filterByClosedManually = hutsStatusList => {
  return hutsStatusList.filter(isClosed)
}

export const deliveryOutsideOfNormalOpeningHours = hut => {
  return ( get(hut, 'summary.web.asap.delivery') === 'UNAVAILABLE_BEFORE_HOURS' ||
  get(hut, 'summary.web.asap.delivery') === 'UNAVAILABLE_AFTER_HOURS' ||
  get(hut, 'summary.web.asap.delivery') === 'UNAVAILABLE_BETWEEN_HOURS' )
}

export const collectionOutsideOfNormalOpeningHours = hut => {
  return ( get(hut, 'summary.web.asap.collection') === 'UNAVAILABLE_BEFORE_HOURS' ||
  get(hut, 'summary.web.asap.collection') === 'UNAVAILABLE_AFTER_HOURS' ||
  get(hut, 'summary.web.asap.collection') === 'UNAVAILABLE_BETWEEN_HOURS' )
}


export const operatingNormallyPartiallyOutsideOfOpeningHours = hut => {
  return ((deliveryOutsideOfNormalOpeningHours(hut) && openForCollection(hut)) || (openForDelivery(hut) && collectionOutsideOfNormalOpeningHours(hut))) && !closedDueToConnectivity(hut) && hutHasDelivery(hut)
}

export const operatingNormallyFullyOutsideOfOpeningHours = hut => {
  return ((!hutHasDelivery(hut) || deliveryOutsideOfNormalOpeningHours(hut)) && collectionOutsideOfNormalOpeningHours(hut)) && !closedDueToConnectivity(hut)
}

export const filterByFullyOutsideOfNormalOpeningHours = hutsStatusList => {
  return hutsStatusList.filter(operatingNormallyFullyOutsideOfOpeningHours)
}

export const filterByPartiallyOutsideOfNormalOpeningHours = hutsStatusList => {
  return hutsStatusList.filter(operatingNormallyPartiallyOutsideOfOpeningHours)
}


export const filterByAtLeastPartiallyInsideOfNormalOpeningHours = hutsStatusList => {
  return hutsStatusList.filter(hut => {
    return operatingNormallyPartiallyOutsideOfOpeningHours(hut) || fullyOpen(hut)
  })
}

export const closedDueToConnectivity = hut => {
  const pos = get(hut, 'overrides.pos', [])
  return pos[pos.length - 1] && pos[pos.length - 1].state === 'UNHEALTHY'
}

export const filterByClosedDueToConnectivity = hutsStatusList => {
  return hutsStatusList.filter(closedDueToConnectivity)
}

export const statusCountsBreakdown = (state, timePeriodFilter) => {
  const normalOpen = joinMetaAndStatusHuts(state, 'normal/open', timePeriodFilter)
  const normalOutOfHours = joinMetaAndStatusHuts(state, 'normal/out-of-hours', timePeriodFilter)
  const colClosed = joinMetaAndStatusHuts(state, 'partially-closed/collection', timePeriodFilter)
  const delClosed = joinMetaAndStatusHuts(state, 'partially-closed/delivery', timePeriodFilter)
  const closedDueToConnectivity = joinMetaPosDowntimeHuts(state, timePeriodFilter, 'longestToShortest')
  const closedManually = joinMetaAndStatusHuts(state, 'closed/manually', timePeriodFilter)

  return {
    normal: normalOpen.length + normalOutOfHours.length,
    'normal/open': normalOpen.length,
    'normal/out-of-hours': normalOutOfHours.length,
    'partially-closed': delClosed.length + colClosed.length,
    'partially-closed/delivery': delClosed.length,
    'partially-closed/collection': colClosed.length,
    closed: closedManually.length + closedDueToConnectivity.length,
    'closed/manually': closedManually.length,
    'closed/connectivity': closedDueToConnectivity.length,
  }
}

export const filterHutsList = (hutsStatusList, status, timePeriodFilter) => {
  switch (status) {
    case 'normal/open':
      return filterByAtLeastPartiallyInsideOfNormalOpeningHours(hutsStatusList)
    case 'normal/partially-open':
      return filterByPartiallyOutsideOfNormalOpeningHours(hutsStatusList)
    case 'normal/out-of-hours':
      return filterByFullyOutsideOfNormalOpeningHours(hutsStatusList)
  }

  if (timePeriodFilter !== 'currently') {
    return hutsStatusList
  }

  switch (status) {
    case 'partially-closed':
      return [...filterByCollectionClosed(hutsStatusList), ...filterByDeliveryClosed(hutsStatusList)]
    case 'partially-closed/delivery':
      return filterByDeliveryClosed(hutsStatusList)
    case 'partially-closed/collection':
      return filterByCollectionClosed(hutsStatusList)
    case 'closed/manually':
      return filterByClosedManually(hutsStatusList)
    case 'closed/connectivity':
      return filterByClosedDueToConnectivity(hutsStatusList)
  }
}

export const getHutStatus = (state, hutId) => {
  return hutsStatusList(state).find(h => h.hutId === hutId)
}


