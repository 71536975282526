import keyBy from 'lodash/keyBy'
import {FETCH_HUTS_META_REQUEST, FETCH_HUTS_META_SUCCESS, FETCH_HUTS_META_FAILURE} from './actions'

export const hutList = (
  state = {
    isFetching: false,
    receivedAt: null,
    list: [],
  },
  action
) => {
  switch (action.type) {
    case FETCH_HUTS_META_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_HUTS_META_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: keyBy(action.list, 'id'),
        receivedAt: action.receivedAt,
      }
    case FETCH_HUTS_META_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}

export const hutsMeta = (state = {}, action) => {
  switch (action.type) {
    case FETCH_HUTS_META_REQUEST:
    case FETCH_HUTS_META_SUCCESS:
    case FETCH_HUTS_META_FAILURE:
      return {
        ...state,
        [action.sector]: hutList(state[action.sector], action),
      }
    default:
      return state
  }
}
