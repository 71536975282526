import React from 'react'
import Card from './Card'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock} from '@fortawesome/free-solid-svg-icons'

export default ({stateTransitions}) => (
  <Card
    icon={<FontAwesomeIcon icon={faClock} className={`text-18 flex-shrink text-palette-amber`} />}
    title="Event History"
  >
    <table>
      {stateTransitions.map(transition => (<tr>
        <td><h3>{transition.state}</h3></td>
        <td>{transition.timestamp}</td>
      </tr>))}
    </table>
  </Card>)