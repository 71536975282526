import {getSector} from 'store/user/selectors'

export const huts = state => state.hutsStatusTotalClosures

export const hutsStatusTotalClosures = (state) => {
  const sector = getSector(state)
  return huts(state)[sector]
}

export const isFetchingHutsStatusTotalClosures = (state) => {
  const hs = hutsStatusTotalClosures(state)
  return hs && hs.isFetching ? hs.isFetching : false
}

export const hutsStatusTotalClosuresList = (state) => {
  const hs = hutsStatusTotalClosures(state)
  return hs && hs.list ? hs.list : {}
}

export const hutsStatusTotalClosuresReceivedAt = (state) => {
  const hs = hutsStatusTotalClosures(state)
  return hs && hs.receivedAt ? hs.receivedAt : 0
}

export const hutsStatusTotalClosuresByHutId = (state, hutId) => {
  return hutsStatusTotalClosuresList(state)[hutId]
}
