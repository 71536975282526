export const SET_USER_PREFERENCES = 'SET_USER_PREFERENCES'
export const SET_SECTOR = 'SET_SECTOR'
export const APPLY_FILTER_REQUEST = 'APPLY_FILTER_REQUEST'
export const APPLY_FILTER_FINISHED = 'APPLY_FILTER_FINISHED'


export const setUserPreferences = preferences => {
  return {
    type: SET_USER_PREFERENCES,
    preferences,
  }
}

export const setSector = sector => {
  return {
    type: SET_SECTOR,
    sector,
  }
}

export const applyFilterRequest = () => {
  return {
    type: APPLY_FILTER_REQUEST,
  }
}

export const applyFilterFinished = () => {
  return {
    type: APPLY_FILTER_FINISHED,
  }
}
