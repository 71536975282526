import React from 'react'
import MenuItems from 'common/MenuItems'
import ActiveAccount from 'common/ActiveAccount'

export const Menu = () => (
  <div className="hidden md:block bg-white z-40 shadow-medium w-px-255">
    <ActiveAccount />
    <MenuItems />
  </div>
)

export default Menu
