import logger from 'utils/logger'
import get from 'lodash/get'
import {getAvailableSectors} from 'utils/sectors'
import {now} from '../../utils/time'

export const auth = state => state.auth
export const isAuthenticated = state => !!idToken(state) && !isTokenExpired(state)
export const attributes = state => get(auth(state), 'attributes')
export const accessToken = state => get(auth(state), 'authentication.token.accessToken')
export const idToken = state => get(auth(state), 'authentication.token.idToken')
const isTokenExpired = state =>
  get(auth(state), 'authentication.token.idToken.payload.exp') < Math.round(now().getTime() / 1000)

export const parsePermissionsAttribute = data => {
  try {
    return JSON.parse(data)
  } catch (err) {
    logger.warn('could not parse permissions attribute value', {data, err})
    return {}
  }
}

export const permissions = state => {
  const attr = attributes(state)
  return attr ? parsePermissionsAttribute(attr['custom:permissions']) : {}
}

export const permittedSectors = state => get(permissions(state), 'sectors', [])

export const getDefaultSector = state => desiredSector => {
  const availableSectors = getAvailableSectors(permittedSectors(state))
  const defaultSector = availableSectors[0]

  if (!desiredSector) {
    return defaultSector
  }

  const allowedDesiredSector = availableSectors.find(sector => sector.sector === desiredSector)
  return allowedDesiredSector || defaultSector
}
