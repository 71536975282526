import {isDateToday, get24HourOpenHour, dayShortCode, convertISODurationToMinutes} from 'utils/time'
import moment from 'moment'
import groupBy from 'lodash/groupBy'

export const openingHoursTodayByDispostion = (listOfHours, disposition, separator) => {
  if (!listOfHours || !listOfHours.length) return
  const hoursForTodayByDisposition = filterHoursByDisposition(filterHoursByToday(listOfHours), disposition)
  return formatOpeningHoursAsCommaString(hoursForTodayByDisposition, separator)
}

export const formatOpeningHoursByDispositionArray = (listOfHours, disposition, separator) => {
  if (!listOfHours || !listOfHours.length) return
  const hoursForTodayByDisposition = filterHoursByDisposition(listOfHours, disposition)
  return openingHoursFormattedArray(hoursForTodayByDisposition, separator)
}

export const filterHoursByDisposition = (listOfHours, disposition) => {
  return listOfHours.filter(hours => hours.disposition === disposition)
}

export const openingHoursFormattedArray = (listOfHours, separator) => {
  return listOfHours.map(hours => {
    return openHourRange(hours.open, hours.close, separator)
  })
}

export const openingHoursFormatted = (listOfHours, separator) => {
  return listOfHours.map(hours => {
    return formattedOpenHourRange(hours.open, hours.close, separator)
  })
}

export const formattedOpenHourRange = (open, close, separator) => {
  return ` ${get24HourOpenHour(open)} ${separator} ${get24HourOpenHour(close)} `
}

export const openHourRange = (open, close, separator) => {
  return [get24HourOpenHour(open), separator, get24HourOpenHour(close)]
}

export const formatOpeningHoursAsCommaString = (listOfHours, separator) => {
  const hours = openingHoursFormatted(listOfHours, separator)
  return `${hours.join(','.substr(0, hours.length - 1))}`
}

export const filterHoursByToday = listOfHours => {
  return listOfHours.filter(slot => {
    return isDateToday(moment(slot.open))
  })
}

export const groupByDayShortcode = hours => {
  hours.map(slot => {
    slot.shortDayCode = dayShortCode(moment(slot.open)).toLowerCase()
  })
  return groupBy(hours, 'shortDayCode')
}

export const groupByDayDate = hours => {
  hours.map(slot => {
    slot.standardDate = moment(slot.open).format('YYYY-MM-DD').toLowerCase()
  })
  return groupBy(hours, 'standardDate')
}

export const compareHutsByClosedAndDowntime = (a, b) => {
  let aMinutes = convertISODurationToMinutes(a.downtime)
  if (a.isClosed) {
    aMinutes = aMinutes + 100000000
  }
  let bMinutes = convertISODurationToMinutes(b.downtime)
  if (b.isClosed) {
    bMinutes = bMinutes + 100000000
  }

  return aMinutes - bMinutes
}