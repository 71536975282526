import {getSector} from 'store/user/selectors'

export const huts = (state) => state.hutsStatusDeliveryClosures

export const hutsStatusDeliveryClosures = (state) => {
  const sector = getSector(state)
  return huts(state)[sector]
}

export const isFetchingHutsStatusDeliveryClosures = (state) => {
  const hs = hutsStatusDeliveryClosures(state)
  return hs && hs.isFetching ? hs.isFetching : false
}

export const hutsStatusDeliveryClosuresList = (state) => {
  const hs = hutsStatusDeliveryClosures(state)
  return hs && hs.list ? hs.list : {}
}

export const hutsStatusDeliveryClosuresReceivedAt = (state) => {
  const hs = hutsStatusDeliveryClosures(state)
  return hs && hs.receivedAt ? hs.receivedAt : 0
}

export const hutsStatusDeliveryClosuresByHutId = (state, hutId) => {
  return hutsStatusDeliveryClosuresList(state)[hutId]
}
