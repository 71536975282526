import {getSector, userPreferences} from 'store/user/selectors'
import {from, to} from 'utils/time'

export const FETCH_HUTS_TOTAL_CLOSURES_REQUEST = 'FETCH_HUTS_TOTAL_CLOSURES_REQUEST'
export const FETCH_HUTS_TOTAL_CLOSURES_SUCCESS = 'FETCH_HUTS_TOTAL_CLOSURES_SUCCESS'
export const FETCH_HUTS_TOTAL_CLOSURES_FAILURE = 'FETCH_HUTS_TOTAL_CLOSURES_FAILURE'

export const fetchAllHutsStatusTotalClosuresRequest = sector => {
  return {
    type: FETCH_HUTS_TOTAL_CLOSURES_REQUEST,
    sector,
  }
}

export const fetchAllHutsStatusTotalClosuresSuccess = (sector, json) => {
  return {
    type: FETCH_HUTS_TOTAL_CLOSURES_SUCCESS,
    sector,
    list: json.data,
    receivedAt: Date.now(),
  }
}

export const fetchAllHutsStatusTotalClosuresFailure = (sector, error) => {
  return {
    type: FETCH_HUTS_TOTAL_CLOSURES_FAILURE,
    sector,
    error,
  }
}

export const fetchAllHutsStatusTotalClosuresBySector = (sector, from, to) => {
  return async dispatch => {
    try {
      dispatch(fetchAllHutsStatusTotalClosuresRequest(sector))
      const url = `https://api.pizzahut.io/v2/huts/status/${sector}/reports/closures/total?from=${from}&to=${to}`
      const result = await fetch(url)
      const json = await result.json()
      return dispatch(fetchAllHutsStatusTotalClosuresSuccess(sector, json))
    } catch (error) {
      return dispatch(fetchAllHutsStatusTotalClosuresFailure(sector, error))
    }
  }
}

export const fetchAllHutsStatusTotalClosures = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const sector = getSector(state)
    const preferences = userPreferences(state)

    return dispatch(fetchAllHutsStatusTotalClosuresBySector(sector, from(preferences.filter.timePeriod), to()))
  }
}