// Font awesome library setup
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faTimes,
  faBars,
  faChevronLeft,
  faChevronRight,
  faHome,
  faDesktop,
  faCircle,
  faMapMarkerAlt,
  faChartLine,
  faCalculator,
  faSlidersH,
  faSearch,
  faExternalLinkAlt,
  faSpinner,
  faSignInAlt,
  faTrashAlt,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import {faHdd, faBuilding, faClock, faStar, faCalendar} from '@fortawesome/free-regular-svg-icons'

// Add icons to reuse across the app here
library.add(
  faTimes,
  faBars,
  faChevronLeft,
  faChevronRight,
  faHome,
  faDesktop,
  faCircle,
  faMapMarkerAlt,
  faClock,
  faChartLine,
  faCalculator,
  faSlidersH,
  faHdd,
  faBuilding,
  faSearch,
  faStar,
  faExternalLinkAlt,
  faSpinner,
  faSignInAlt,
  faTrashAlt,
  faPlusCircle,
  faCalendar,
)
