import {getSector} from 'store/user/selectors'
import {getFlag} from 'store/flag/selectors'

export const FETCH_HUTS_META_REQUEST = 'FETCH_HUTS_META_REQUEST'
export const FETCH_HUTS_META_SUCCESS = 'FETCH_HUTS_META_SUCCESS'
export const FETCH_HUTS_META_FAILURE = 'FETCH_HUTS_META_FAILURE'

export const fetchHutsMetaRequest = (sector) => {
  return {
    type: FETCH_HUTS_META_REQUEST,
    sector,
  }
}

export const fetchHutsMetaSuccess = (sector, json) => {
  return {
    type: FETCH_HUTS_META_SUCCESS,
    sector,
    list: json,
    receivedAt: Date.now(),
  }
}

export const fetchHutsMetaFailure = (sector, error) => {
  return {
    type: FETCH_HUTS_META_FAILURE,
    sector,
    error,
  }
}

export const fetchAllHutsMetadataBySector = (sector, featureRole) => {
  return async (dispatch) => {
    try {
      dispatch(fetchHutsMetaRequest(sector))
      const url = `https://api.pizzahut.io/v1/huts/?sector=${sector}&includeFullOpeningHours=true&openDays=7`
      const options = {
        headers: {
          'x-feature-role': featureRole || 'none',
        },
      }
      const result = await fetch(url, options)
      const json = await result.json()
      return dispatch(fetchHutsMetaSuccess(sector, json))
    } catch (error) {
      return dispatch(fetchHutsMetaFailure(sector, error))
    }
  }
}

export const fetchAllHutsMetadata = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const sector = getSector(state)
    const featureRole = getFlag(state, 'featureRole')

    return dispatch(fetchAllHutsMetadataBySector(sector, featureRole))
  }
}
