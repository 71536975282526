import {setFlag, resetFlags} from './actions'
import {getFlag, getAll} from './selectors'

export const exposeFlagUtils = (store) => {
    window.flags = {
        get: (flag) => getFlag(store.getState(), flag),
        getAll: () => getAll(store.getState()),
        set: (flag, value) => store.dispatch(setFlag(flag, value)),
        reset: () => store.dispatch(resetFlags()),
    }
}