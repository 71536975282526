import {REQUEST_SET_CREDENTIALS, SET_CREDENTIALS_ERROR, SET_CREDENTIALS_SUCCESS} from './actions'

export const hutCredentials = (
  state = {
    inProgress: false,
    error: undefined,
  },
  action
) => {
  switch (action.type) {
    case REQUEST_SET_CREDENTIALS:
      return {
        ...state,
        inProgress: true,
      }
    case SET_CREDENTIALS_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: undefined,
      }
    case SET_CREDENTIALS_ERROR:
      return {
        ...state,
        inProgress: false,
        error: action.error,
      }

    default:
      return state
  }
}
