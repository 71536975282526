import React from 'react'
import Card from './Card'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faShoppingBasket} from '@fortawesome/free-solid-svg-icons'

export default ({payment}) => (
  <Card
    icon={<FontAwesomeIcon icon={faShoppingBasket} className={`text-18 flex-shrink text-palette-amber`} />}
    title="Basket"
    >
    <h3>
      Payment
    </h3>
    {payment.amount} - {payment.type}
  </Card>
)