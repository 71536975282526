import {FETCH_ORDER_SUCCESS, FETCH_ORDER_FAILURE, FETCH_ORDER_REQUEST} from './actions'

export const order = (
  state = {},
  action
) => {
  switch (action.type) {
    case FETCH_ORDER_REQUEST:
      return {
        ...state,
        isFetching: true,
        order: undefined,
      }
    case FETCH_ORDER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        order: action.order,
      }
    case FETCH_ORDER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    default:
      return state
  }
}