import React from 'react'
import ToggleMenu from 'common/ToggleMenu'
import Search from 'common/Search'
import SectorSelect from 'common/SectorSelect'
import {withRouter} from 'react-router'

import logoImageDesktop from 'images/logos/logo_wide_alternate@2x.png'
import logoImageMobile from 'images/logos/logo_mob@x2.png'

const isOnHutsPageRegex = /^\/sector\/.+\/huts$/
const isOnHutsPage = (location) => isOnHutsPageRegex.test(location)

export const Header = ({location}) => {
  return (
    <nav className="w-full flex flex-wrap md:flex-no-wrap items-center justify-between bg-white mb-10 md:mb-0 shadow-medium z-50">
      <ToggleMenu />
      <div className="flex items-center flex-shrink-0 ml-20">
        <picture>
          <source srcSet={logoImageDesktop} media="(min-width: 768px)" />
          <source srcSet={logoImageMobile} />
          <img src={logoImageMobile} alt="pizza hut logo" className="h-px-30 md:h-px-45 w-auto" />
        </picture>
      </div>
      <SectorSelect />
      {!isOnHutsPage(location.pathname) && <Search />}
    </nav>
  )
}

export default withRouter(Header)
